import { gql } from "@apollo/client"

export const SONG_QUERY = gql`
  query($where: JSON) {
    piosenkis(where: $where) {
      title
      slug
      lyrics
      chords
      audio {
        title
        file {
          url
        }
      }
      sheets {
        title
        file {
          url
        }
      }
      youtube {
        youtube
      }
    }
  }
`
export type SongType = {
  title: string
  slug: string
  lyrics: string
  chords: string
  audio: {
    title: string
    file: {
      url: string
    }
  }[]
  sheets: {
    title: string
    file: {
      url: string
    }
  }[]
  youtube: {
    youtube: string
  }[]
}

export type SONG_QUERY_TYPE = {
  piosenkis: SongType[]
}
