import React, { FC, useEffect, useRef, useState } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  overflow: hidden;
  transition: height 0.3s;
`

type Props = {
  isCollapsed: boolean
  collapsedHeight?: number
}

const Collapse: FC<Props> = ({ isCollapsed, collapsedHeight, children }) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const innerRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(
    isCollapsed ? collapsedHeight || 0 : innerRef.current?.offsetHeight
  )

  useEffect(() => {
    setHeight(
      isCollapsed ? collapsedHeight || 0 : innerRef.current?.offsetHeight
    )
  }, [children, isCollapsed, collapsedHeight, height])

  useEffect(() => {
    if (innerRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        setHeight(
          isCollapsed
            ? collapsedHeight
              ? collapsedHeight
              : 0
            : innerRef.current?.offsetHeight
        )
      })

      resizeObserver.observe(innerRef.current)
    }
  }, [innerRef])

  return (
    <Wrapper
      ref={wrapperRef}
      style={{
        height: height + "px",
      }}
    >
      <div ref={innerRef}>{children}</div>
    </Wrapper>
  )
}

export default Collapse
