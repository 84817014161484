import styled from "styled-components"

const ContentWrapper = styled.div<{
  paddingTop?: boolean
  paddingTopFilters?: boolean
  full?: boolean
}>`
  margin: 92px 24px 24px 24px;
  padding-bottom: 64px;

  ${props => props.theme.media.md} {
    margin: 80px 64px 64px 240px;
    padding-left: 48px;
  }

  ${props => props.theme.media.xl} {
    margin: 16px ${props => (props.full ? `64px` : `378px`)} 64px 240px;
    max-width: ${props => (props.full ? "1000px" : "820px")};
    ${props =>
      (props.paddingTop || props.paddingTopFilters) && "padding-top: 64px"};
  }
`

export default ContentWrapper
