import styled from "styled-components"

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  z-index: 2000;
`
const Modal = styled.div`
  width: 400px;
`

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  z-index: 2001;
  padding: 24px 0;
`

const Styled = {
  Overlay,
  Modal,
  ModalWrapper,
}

export default Styled
