import { gql } from "@apollo/client"

export const WPIS_QUERY = gql`
  query getWpis($where: JSON) {
    articles(where: $where) {
      title
      slug
      content
      published_at
      gallery {
        url
        width
        height
      }
      topic_group {
        title
        slug
      }
    }
  }
`

export type WPIS_QUERY_TYPE = {
  articles: {
    title: string
    slug: string
    content: string
    published_at: string
    gallery: {
      url: string
      width: number
      height: number
    }[]
    topic_group: {
      title: string
      slug: string
    }
  }[]
}
