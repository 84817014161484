import { gql } from "@apollo/client"

export const PAST_TRIP_QUERY = gql`
  query GetPastTrip($where: JSON) {
    trips(where: $where) {
      title
      location
      from
      to
      slug
      image {
        url
      }
      story
      conferences {
        title
        file {
          url
        }
      }
      gallery {
        url
        width
        height
      }
    }
  }
`

export type PAST_TRIP_QUERY_TYPE = {
  trips: {
    title: string
    location: string
    from: string
    to: string
    slug: string
    image?: {
      url: string
    }
    story?: string
    conferences?: {
      title: string
      file: {
        url: string
      }
    }[]
    gallery: {
      url: string
      width: number
      height: number
    }[]
  }[]
}
