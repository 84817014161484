import { checkmarkOutline, filterOutline } from "ionicons/icons"
import React, { useEffect, useRef, useState } from "react"
import Styled from "./Filter.styled"
import Icon from "components/Icon"

export type FilterProps = {
  placeholder?: string
  currentOption: FilterOption
  options: FilterOptions
  handleSelectFn: (option: FilterOption) => void
  width?: number
  noPadding?: boolean
}

export interface FilterOption {
  title: string
  value: string
}

export interface FilterOptions {
  [key: string]: FilterOption
}

export const filterOptionsNewestOldest: FilterOptions = {
  newest: {
    title: "Najnowsze",
    value: "date:desc",
  },
  oldest: {
    title: "Najstarsze",
    value: "date:asc",
  },
}

const Filter = ({
  placeholder,
  currentOption,
  options,
  handleSelectFn,
  width,
  noPadding,
}: FilterProps) => {
  const [isOpened, setIsOpened] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleButtonClick = () => {
    setIsOpened(!isOpened)
  }

  const handleDocumentClick = (e: MouseEvent) => {
    if (!wrapperRef.current!.contains(e.target as Node)) {
      setIsOpened(false)
    }
  }

  const handleOptionClick = (option: FilterOption) => {
    setIsOpened(false)
    handleSelectFn(option)
  }

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick)
    return () => {
      document.removeEventListener("click", handleDocumentClick)
    }
  }, [])

  return (
    <Styled.Wrapper ref={wrapperRef}>
      <Styled.Button
        onClick={handleButtonClick}
        isOpened={isOpened}
        width={width}
        style={noPadding ? { padding: 0 } : {}}
      >
        <Styled.IconWrapper>
          <Icon icon={filterOutline} />
        </Styled.IconWrapper>
        {placeholder && !currentOption
          ? placeholder
          : currentOption.title
          ? currentOption.title
          : ""}{" "}
      </Styled.Button>
      {isOpened && (
        <Styled.Select width={width}>
          {Object.keys(options).map(key => (
            <Styled.SelectItem
              key={key}
              onClick={() => handleOptionClick(options[key])}
            >
              <Styled.SelectItemIcon>
                {options[key] === currentOption && (
                  <Icon icon={checkmarkOutline} />
                )}
              </Styled.SelectItemIcon>
              {options[key].title}
            </Styled.SelectItem>
          ))}
        </Styled.Select>
      )}
    </Styled.Wrapper>
  )
}

export default Filter
