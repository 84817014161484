import ContentWrapper from "components/ContentWrapper"
import PageTransition from "components/PageTransition"
import React, { useEffect, useMemo, useState } from "react"
import SideWrapper from "components/SideWrapper"
import { ViewContextConsumer } from "context/viewContext"
import Widget, { WidgetSection } from "components/Widget"
import Label from "components/Label"
import Row from "components/Row"
import Styled from "./Informacje.styled"
import Collapse, { CollapseButton } from "components/Collapse"
import Separator from "components/Separator"
import Heading from "components/Heading"
import Filter from "components/Filter"
import {
  MobileMenuBarContextType,
  withMobileMenuBarContext,
} from "components/UserMenuBar/MobileMenuBar/MobileMenuBarContext"
import { useQuery } from "@apollo/client"
import {
  INFORMATIONS_QUERY,
  INFORMATIONS_QUERY_TYPE,
} from "konto/Informacje/Informacje.gql"
import { parseDateForGql } from "tools/parseDateForGql"
import Spinner from "components/Spinner"
import { FilterOption, FilterOptions } from "components/Filter/Filter"
import NoResults from "components/NoResults"
import { Helmet } from "react-helmet"

const SortedUsers = ({
  users,
}: {
  users?: { name: string; surname: string }[]
}) => {
  const sortedUsers = useMemo(() => {
    const newUsers = [...(users || [])]
    newUsers.sort((a, b) => a.surname.trim().localeCompare(b.surname.trim()))
    return newUsers
  }, [users])

  return (
    <>
      {sortedUsers.map((user, index) => (
        <div key={index}>
          {user.name} {user.surname}
        </div>
      ))}
    </>
  )
}

const GroupsList = ({
  data,
  loading,
}: {
  data?: INFORMATIONS_QUERY_TYPE
  loading: boolean
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const groups = useMemo(() => {
    const groupsArray = []
    if (data && data.groups.length) {
      groupsArray.push(...data.groups)
      groupsArray.sort((a, b) =>
        parseInt(a.name.split(" ")[1]) > parseInt(b.name.split(" ")[1]) ? 1 : -1
      )
    }
    return groupsArray
  }, [data])

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }
  return (
    <>
      <Helmet>
        <title>Informacje | Wspólnota 120</title>
      </Helmet>
      <Widget gray margin>
        <WidgetSection smallPadding>
          <Heading level={3}>Lista grup</Heading>
        </WidgetSection>
        <Separator white />
        <Collapse isCollapsed={isCollapsed} collapsedHeight={120}>
          <WidgetSection>
            <Row
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              flexWrap
            >
              {loading ? (
                <Spinner size={24} />
              ) : groups.length ? (
                groups.map(group => (
                  <Styled.GroupColumn key={group.name}>
                    <strong>{group.name}</strong>
                    <SortedUsers users={group.users} />
                  </Styled.GroupColumn>
                ))
              ) : (
                <NoResults>Brak wyników</NoResults>
              )}
            </Row>
          </WidgetSection>
        </Collapse>
        <Separator white />
        <CollapseButton
          isCollapsed={isCollapsed}
          onClick={handleToggleCollapse}
          block
        />
      </Widget>
    </>
  )
}

type Participant = {
  name: string
  surname: string
  group: number
}

const ParticipantsList = ({
  data,
  loading,
}: {
  data?: INFORMATIONS_QUERY_TYPE
  loading: boolean
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const filterOptions: FilterOptions = {
    surname: {
      title: "Nazwiska alfabetycznie",
      value: "surname",
    },
    name: {
      title: "Imiona alfabetycznie",
      value: "name",
    },
    group: {
      title: "Numer grupy",
      value: "group",
    },
  }

  const sorters: {
    [name: string]: (a: Participant, b: Participant) => number
  } = {
    surname: (a, b) => a.surname.trim().localeCompare(b.surname.trim()),
    name: (a, b) => a.name.trim().localeCompare(b.name.trim()),
    group: (a, b) => (a.group > b.group ? 1 : -1),
  }

  const [selectedFilter, setSelectedFilter] = useState(filterOptions.surname)

  const handleFilterSelectFn = (value: FilterOption) => {
    setSelectedFilter(value)
  }

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  const participants = useMemo(() => {
    if (data && data.groups) {
      const list = data.groups
        .map(group =>
          group.users.map(({ name, surname }) => ({
            name,
            surname,
            group: parseInt(group.name.split(" ")[1]),
          }))
        )
        .flat()
        .sort(sorters["surname"])
        .sort(sorters[selectedFilter.value])
      return list
    }
    return []
  }, [data, selectedFilter])

  return (
    <ViewContextConsumer>
      {viewContext => (
        <>
          <Widget margin gray>
            <WidgetSection smallPadding>
              {(viewContext?.media.md ||
                viewContext?.media.lg ||
                viewContext?.media.xl) && (
                <Row>
                  <Heading level={3}>Lista uczestników</Heading>
                  <Filter
                    currentOption={selectedFilter}
                    options={filterOptions}
                    handleSelectFn={handleFilterSelectFn}
                    width={200}
                  />
                </Row>
              )}

              {(viewContext?.media.xs || viewContext?.media.sm) && (
                <div>
                  <Heading level={3}>Lista uczestników</Heading>

                  <Filter
                    currentOption={selectedFilter}
                    options={filterOptions}
                    handleSelectFn={handleFilterSelectFn}
                    width={200}
                    noPadding
                  />
                </div>
              )}
            </WidgetSection>
            <Separator white />
            <Collapse isCollapsed={isCollapsed} collapsedHeight={120}>
              <Styled.Participants>
                <Styled.Participant>
                  <Styled.ParticipantName>Imię Nazwisko</Styled.ParticipantName>
                  <Styled.ParticipantGroup>Grupa</Styled.ParticipantGroup>
                </Styled.Participant>

                <Separator white />

                {loading ? (
                  <Spinner size={24} />
                ) : participants.length ? (
                  <>
                    {participants.map((participant, index) => (
                      <Styled.Participant key={index}>
                        <Styled.ParticipantName>
                          {participant.name} {participant.surname}
                        </Styled.ParticipantName>
                        <Styled.ParticipantGroup>
                          {participant.group}
                        </Styled.ParticipantGroup>
                      </Styled.Participant>
                    ))}
                  </>
                ) : (
                  <NoResults>Brak uczestników</NoResults>
                )}
              </Styled.Participants>
            </Collapse>
            <Separator white />
            <CollapseButton
              isCollapsed={isCollapsed}
              onClick={handleToggleCollapse}
              block
            />
          </Widget>
        </>
      )}
    </ViewContextConsumer>
  )
}

const ResponsiblePeople = ({
  data,
  loading,
}: {
  data?: INFORMATIONS_QUERY_TYPE
  loading: boolean
}) => (
  <>
    {((data && !!data.information.leaders.length) || loading) && (
      <Widget margin>
        <WidgetSection>
          <Label>Osoby odpowiedzialne</Label>
          {loading ? (
            <Spinner size={24} />
          ) : (
            data &&
            data.information.leaders.map(leader => (
              <div key={leader.name} style={{ marginTop: "12px" }}>
                <strong>{leader.name}</strong>
                {!!leader.role?.length && <>– {leader.role}</>}
                {!!leader.email?.length && (
                  <>
                    <br />
                    e-mail:{" "}
                    <a href={`mailto:${leader.email}`}>{leader.email}</a>
                  </>
                )}
                {!!leader.phone?.length && (
                  <>
                    <br />
                    tel.:
                    <a href={`tel:${leader.phone}`}>{leader.phone}</a>
                  </>
                )}
              </div>
            ))
          )}
        </WidgetSection>
      </Widget>
    )}
  </>
)

const CookiesDuty = ({
  data,
  loading,
}: {
  data?: INFORMATIONS_QUERY_TYPE
  loading: boolean
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <>
      {((data && !!data.meetings) || loading) && (
        <Widget margin gray>
          <Collapse isCollapsed={isCollapsed} collapsedHeight={120}>
            <WidgetSection smallPadding>
              <Heading level={3}>Dyżur ciastkowy</Heading>

              {data && !!data.meetings.length ? (
                data.meetings.map(meeting => (
                  <Row
                    key={meeting.date}
                    alignItems={"flex-start"}
                    justifyContent={"flex-start"}
                    flexWrap
                  >
                    <Styled.CookieColumn>
                      <strong>{meeting.date} – wczesny</strong>
                      <br />
                      {meeting.cookies_early_users?.length ? (
                        <SortedUsers users={meeting.cookies_early_users} />
                      ) : (
                        "–"
                      )}
                    </Styled.CookieColumn>
                    <Styled.CookieColumn>
                      <strong>{meeting.date} – późny</strong>
                      <br />
                      {meeting.cookies_late_users?.length ? (
                        <SortedUsers users={meeting.cookies_late_users} />
                      ) : (
                        "–"
                      )}
                    </Styled.CookieColumn>
                  </Row>
                ))
              ) : loading ? (
                <Spinner size={24} />
              ) : (
                <div>Brak nadchodzących spotkań</div>
              )}
            </WidgetSection>
          </Collapse>
          <Separator white />
          <CollapseButton
            isCollapsed={isCollapsed}
            onClick={handleToggleCollapse}
            block
          />
        </Widget>
      )}
    </>
  )
}

const Informacje = ({ setMobileMenuBarValues }: MobileMenuBarContextType) => {
  const today = new Date()

  const { loading, error, data } = useQuery<INFORMATIONS_QUERY_TYPE>(
    INFORMATIONS_QUERY,
    {
      variables: {
        meetingsWhere: {
          date_gt: parseDateForGql(today),
        },
      },
    }
  )

  useEffect(() => {
    if (setMobileMenuBarValues) {
      setMobileMenuBarValues({
        type: "menu",
        title: "Informacje",
      })
    }
  }, [])

  return (
    <ViewContextConsumer>
      {viewContext => (
        <>
          <ContentWrapper paddingTop>
            <PageTransition>
              {(viewContext?.media.xs ||
                viewContext?.media.sm ||
                viewContext?.media.md ||
                viewContext?.media.lg) && (
                <div>
                  <ResponsiblePeople data={data} loading={loading} />
                </div>
              )}
              <GroupsList data={data} loading={loading} />
              <ParticipantsList data={data} loading={loading} />
              <CookiesDuty loading={loading} data={data} />
            </PageTransition>
          </ContentWrapper>
          {viewContext?.media.xl && (
            <SideWrapper>
              <PageTransition style={{ height: "100%" }}>
                <ResponsiblePeople data={data} loading={loading} />
              </PageTransition>
            </SideWrapper>
          )}
        </>
      )}
    </ViewContextConsumer>
  )
}
export default withMobileMenuBarContext(Informacje)
