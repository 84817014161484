import { gql, useQuery } from "@apollo/client"

const userQueryGQL = gql`
  query {
    me {
      username
      email
    }
  }
`

export const useUserQuery = () => useQuery(userQueryGQL)
