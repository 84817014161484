import { gql } from "@apollo/client"

export const INCOMING_TRIP_QUERY = gql`
  query GetIncomingTrip($where: JSON) {
    trips(where: $where) {
      title
      location
      from
      to
      slug
      image {
        url
      }
      announcement
      register_description
      register_link
    }
  }
`

export type INCOMING_TRIP_QUERY_TYPE = {
  trips: {
    title: string
    location: string
    from: string
    to: string
    slug: string
    image?: {
      url: string
    }
    announcement: string
    register_description?: string
    register_link?: string
  }[]
}
