import React, { FC } from "react"
import styled from "styled-components"

const PageTransition = styled.div<{ absolute?: boolean }>`
  opacity: 0;
  animation-duration: 0.5s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 0.6s;
  ${props =>
    props.absolute
      ? "position: absolute; top: 0; left: 0; right: 0;"
      : "position: relative;"}

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(12px);
    }
    100% {
      opacity: 1;

      transform: translateY(0px);
    }
  }
`

export default PageTransition
