import React, { useEffect, useState } from "react"
import { Redirect } from "@reach/router"
import loadable from "@loadable/component"
import Theme from "theme/Theme"
import { ViewContextProvider } from "context/viewContext"
import { navigate, PageProps } from "gatsby"

import { useAuthToken } from "services/authToken"
import { useUserQuery } from "services/getUserData"
import { ApolloProvider } from "@apollo/client"
import { useAppApolloClient } from "services/client"
import Informacje from "konto/Informacje"
import WyjazdyNadchodzace from "konto/Wyjazdy/Nadchodzące"
import WyjazdyMinione from "konto/Wyjazdy/Minione"
import NadchodzacyWyjazd from "konto/Wyjazdy/Nadchodzące/NadchodzacyWyjazd"
import MinionyWyjazd from "konto/Wyjazdy/Minione/MinionyWyjazd"
import FadeTransitionRouter from "components/FadeTransitionRouter"
import Spiewnik from "konto/Spiewnik/Spiewnik"
import Piosenka from "konto/Spiewnik/Piosenka/Piosenka"
import Grupy from "konto/Grupy/Grupy"
import Grupa from "konto/Grupy/Grupa/Grupa"
import Wpis from "konto/Grupy/Grupa/Wpis/Wpis"
import Ustawienia from "konto/Ustawienia"
import SpotkaniaElementy from "konto/SpotkaniaElementy"
import Aktualnosci from "konto/Aktualnosci/Aktualnosci"
import AktualnosciWpis from "konto/Aktualnosci/Wpis/AktualnosciWpis"

const UserLayout = loadable(() => import("layouts/UserLayout"))
const Spotkania = loadable(() => import("konto/Spotkania"))
const Spotkanie = loadable(() => import("konto/Spotkanie"))
const Kalendarz = loadable(() => import("konto/Kalendarz"))

const UserPage = ({ location }: { location: Location }) => {
  const [allow, setAllow] = useState(false)
  const [authToken] = useAuthToken()
  const userData = useUserQuery()
  useEffect(() => {
    if (!authToken || !userData.data) {
      navigate("/login")
    } else {
      setAllow(true)
      if (location.pathname === "/konto" || location.pathname === "/konto/") {
        navigate("/konto/spotkania/")
      }
    }
  }, [location])

  return allow ? (
    <UserLayout>
      <FadeTransitionRouter>
        <div className={"page"} path={"/konto/spotkania"}>
          <Spotkania path={"/"} />
        </div>
        <div className={"page"} path={"/konto/spotkania/:slug"}>
          <Spotkanie path={"/"} />
        </div>
        <div className={"page"} path={"/konto/aktualnosci/"}>
          <Aktualnosci path={"/"} />
        </div>
        <div className={"page"} path={"/konto/aktualnosci/:slug"}>
          <AktualnosciWpis path={"/"} />
        </div>
        <div className={"page"} path={"/konto/wyjazdy/nadchodzace"}>
          <WyjazdyNadchodzace path={"/"} />
        </div>
        <div className={"page"} path={"/konto/wyjazdy/nadchodzace/:slug"}>
          <NadchodzacyWyjazd path={"/"} />
        </div>
        <div className={"page"} path={"/konto/wyjazdy/minione"}>
          <WyjazdyMinione path={"/"} />
        </div>
        <div className={"page"} path={"/konto/wyjazdy/minione/:slug"}>
          <MinionyWyjazd path={"/"} />
        </div>
        <div className={"page"} path={"/konto/kalendarz"}>
          <Kalendarz path={"/"} />
        </div>
        <div className={"page"} path={"/konto/informacje"}>
          <Informacje path={"/"} />
        </div>
        <div className={"page"} path={"/konto/grupy-tematyczne"}>
          <Grupy path={"/"} />
        </div>
        <div className={"page"} path={"/konto/grupy-tematyczne/:group"}>
          <Grupa path={"/"} />
        </div>
        <div className={"page"} path={"/konto/grupy-tematyczne/:group/:slug"}>
          <Wpis path={"/"} />
        </div>
        <div className={"page"} path={"/konto/spiewnik"}>
          <Spiewnik path={"/"} />
        </div>
        <div className={"page"} path={"/konto/spiewnik/:slug"}>
          <Piosenka path={"/"} />
        </div>
        <div className={"page"} path={"/konto/ustawienia"}>
          <Ustawienia path={"/"} />
        </div>
        <div className={"page"} path={"/konto"}>
          <SpotkaniaElementy path={"/:element"} />
        </div>
        <Redirect
          noThrow
          from={"/konto/wyjazdy"}
          to={"/konto/wyjazdy/nadchodzace"}
        />
      </FadeTransitionRouter>
    </UserLayout>
  ) : null
}

const Konto = ({ location }: PageProps) => {
  const client = useAppApolloClient()

  return (
    <>
      <ApolloProvider client={client}>
        <ViewContextProvider>
          <Theme>
            <UserPage location={location} />
          </Theme>
        </ViewContextProvider>
      </ApolloProvider>
    </>
  )
}

export default Konto
