import PageTransition from "components/PageTransition"
import ContentWrapper from "components/ContentWrapper"
import Link from "components/Link"
import {
  chevronBack,
  chevronForwardOutline,
  personAddOutline,
  readerOutline,
} from "ionicons/icons"
import Heading from "components/Heading"
import Subheading from "components/Subheading"
import React, { useEffect } from "react"
import styled from "styled-components"
import WIcon from "components/WIcon"
import SectionTitle from "components/SectionTitle"
import Entry from "components/Entry"
import {
  MobileMenuBarContextType,
  withMobileMenuBarContext,
} from "components/UserMenuBar/MobileMenuBar/MobileMenuBarContext"
import { ViewContextConsumer } from "context/viewContext"
import { useLocation } from "@reach/router"
import { useQuery } from "@apollo/client"
import {
  INCOMING_TRIP_QUERY,
  INCOMING_TRIP_QUERY_TYPE,
} from "konto/Wyjazdy/Nadchodzące/NadchodzacyWyjazd/NadchodzacyWyjazd.gql"
import { parseStringDate } from "tools/parseStringDate"
import Spinner from "components/Spinner"
import config from "config/config"
import { Helmet } from "react-helmet"

const StyledImage = styled.div.attrs<{ src: string }>(({ src }) => ({
  style: {
    backgroundImage: `url(${src})`,
  },
}))<{ src: string }>`
  padding-top: 50%;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  position: relative;
  margin-bottom: 28px;
`

const NadchodzacyWyjazd = ({
  setMobileMenuBarValues,
}: MobileMenuBarContextType) => {
  const location = useLocation()
  const slug = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  )

  const { loading, error, data } = useQuery<INCOMING_TRIP_QUERY_TYPE>(
    INCOMING_TRIP_QUERY,
    {
      variables: {
        where: {
          slug: slug,
        },
      },
    }
  )

  useEffect(() => {
    if (data && data.trips && data.trips[0]) {
      const trip = data.trips[0]
      if (setMobileMenuBarValues) {
        setMobileMenuBarValues({
          type: "entry",
          title: `${trip.title}`,
          subtitle: `${trip.location}, ${parseStringDate(
            trip.from
          )} – ${parseStringDate(trip.to)}`,
          backUrl: "/konto/wyjazdy/nadchodzace",
        })
      }
    }
  }, [data, data?.trips, data?.trips[0]])

  if (data && data.trips && data.trips[0]) {
    const {
      title,
      location,
      from,
      to,
      announcement,
      register_description,
      register_link,
      image,
    } = data.trips[0]

    return (
      <>
        <Helmet>
          <title>{title} | Wspólnota 120</title>
        </Helmet>
        <ViewContextConsumer>
          {viewContext => (
            <PageTransition>
              <ContentWrapper>
                <Entry.Header>
                  {image && (
                    <StyledImage src={config.uploadPrefix + image.url} />
                  )}

                  {(viewContext?.media.md ||
                    viewContext?.media.lg ||
                    viewContext?.media.xl) && (
                    <Link icon={chevronBack} to={"/konto/wyjazdy/nadchodzace"}>
                      Powrót
                    </Link>
                  )}
                  <Heading>{title}</Heading>
                  <Subheading>
                    {location}, {parseStringDate(from)} – {parseStringDate(to)}
                  </Subheading>
                </Entry.Header>
                {announcement && !!announcement.length && (
                  <Entry.Section>
                    <SectionTitle withLine>
                      <WIcon icon={readerOutline} />
                      Informacje
                    </SectionTitle>
                    <div
                      style={{ marginBottom: "12px" }}
                      dangerouslySetInnerHTML={{ __html: announcement }}
                    />
                  </Entry.Section>
                )}

                {(register_description || register_link) && (
                  <Entry.Section>
                    <SectionTitle withLine>
                      <WIcon icon={personAddOutline} />
                      Zapisy
                    </SectionTitle>
                    <div style={{ marginBottom: "12px" }}>
                      {register_description && (
                        <div
                          style={{ marginBottom: "12px" }}
                          dangerouslySetInnerHTML={{
                            __html: register_description,
                          }}
                        />
                      )}
                      {register_link && (
                        <p>
                          <Link
                            to={register_link}
                            icon={chevronForwardOutline}
                            iconPosition={"right"}
                          >
                            Przejdź do formularza
                          </Link>
                        </p>
                      )}
                    </div>
                  </Entry.Section>
                )}
              </ContentWrapper>
            </PageTransition>
          )}
        </ViewContextConsumer>
      </>
    )
  } else if (loading) {
    return (
      <PageTransition>
        <ContentWrapper>
          <Spinner size={32} />
        </ContentWrapper>
      </PageTransition>
    )
  } else if (error) {
    return (
      <>
        <div></div>
      </>
    )
  }
  return null
}

export default withMobileMenuBarContext(NadchodzacyWyjazd)
