import WidgetSection from "components/Widget/WidgetSection"
import Label from "components/Label"
import Input from "components/Input"
import Button from "components/Button"
import Widget from "components/Widget"
import React, { useMemo, useState } from "react"
import Spinner from "components/Spinner"
import { gql, useMutation, useQuery } from "@apollo/client"
import Alert from "components/Alert"

const UPDATE_PASSWORD = gql`
  mutation updatePassword($password: String!, $userId: ID!) {
    updateUser(
      input: { where: { id: $userId }, data: { password: $password } }
    ) {
      user {
        username
      }
    }
  }
`

const ME_QUERY = gql`
  query {
    me {
      id
    }
  }
`

type ME_QUERY_TYPE = {
  me: {
    id: string
  }
}

const PasswordForm = () => {
  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")
  const [loadingButton, setLoadingButton] = useState(false)
  const [passwordError, setPasswordError] = useState("")
  const [passwordSuccess, setPasswordSuccess] = useState("")

  const { loading, error, data, refetch } = useQuery<ME_QUERY_TYPE>(ME_QUERY)
  const [updatePassword] = useMutation(UPDATE_PASSWORD)

  const saveActive = useMemo(
    () => !!password.length && !!passwordConfirm.length,
    [password, passwordConfirm]
  )

  const handleSubmit = () => {
    if (data && data.me) {
      if (password.length < 6 || password.length > 16) {
        setPasswordError(
          "Hasło musi zawierać przynajmniej 6 znaków i nie więcej niż 16 znaków."
        )
        return
      }
      if (password !== passwordConfirm) {
        setPasswordError("Podane hasła nie są identyczne.")
        return
      }
      setPasswordError("")
      updatePassword({
        variables: {
          userId: data.me.id,
          password: password,
        },
      })
        .then(res => {
          if (res.data) {
            setPasswordError("")
            setPasswordSuccess("Hasło zostało zmienione.")
          }
        })
        .catch(err => {
          setPasswordError("Wystąpił nieznany błąd. Proszę spróbować później.")
        })
    }
  }

  return (
    <Widget>
      <WidgetSection>
        <Label>Zmiana hasła</Label>
        <div style={{ marginBottom: "16px" }}>
          <Input
            type={"password"}
            label={"Nowe hasło"}
            value={password}
            onChange={e => setPassword(e.currentTarget.value)}
            block
          />

          <Input
            type={"password"}
            label={"Powtórz nowe hasło"}
            value={passwordConfirm}
            onChange={e => setPasswordConfirm(e.currentTarget.value)}
            block
          />
        </div>

        <div style={{ marginBottom: "24px" }}>
          <Alert isActive={!!passwordError.length} error>
            {passwordError}
          </Alert>
          <Alert isActive={!!passwordSuccess.length} success>
            {passwordSuccess}
          </Alert>
        </div>

        <Button
          disabled={!saveActive}
          onClick={handleSubmit}
          style={{ width: "120px" }}
        >
          {loadingButton ? <Spinner size={24} /> : "Zapisz"}
        </Button>
      </WidgetSection>
    </Widget>
  )
}

export default PasswordForm
