import styled from "styled-components"

const Wrapper = styled.div<{ block?: boolean }>`
  position: relative;
  z-index: 1;
  margin: 16px 0;
  display: ${props => (props.block ? "block" : "inline-block")};
`

const InputUnderline = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0px;
  border-radius: 6px 6px 1px 1px;
  z-index: -2;
  background-color: ${props => props.theme.color.primaryLighter};

  transition-duration: 0.2s;

  &::before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 2px;
    background-color: ${props => props.theme.color.black};
    border-radius: 2px;
  }
`

const Label = styled.label<{
  hasIcon?: boolean
  isFilled: boolean
  required?: boolean
}>`
  color: ${props => props.theme.color.black};
  opacity: ${props => (props.isFilled ? 0.8 : 0.5)};
  transition-duration: 0.1s;
  position: absolute;
  z-index: -1;
  top: 50%;
  font-weight: bold;
  transform-origin: left top;
  left: ${props => (props.hasIcon ? "48px" : "12px")};
  transform: ${props =>
    props.isFilled ? "translateY(-110%) scale(0.7)" : "translateY(-35%)"};

  ${props =>
    props.required &&
    `
    &::after {
      content: "*";
      display: inline;
      color: ${props.theme.color.error};
    }
  `}
`

const InputElement = styled.input<{
  block?: boolean
  hasIcon: boolean
  large?: boolean
}>`
  border: none;
  outline: none;
  background: none;
  height: 52px;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  padding: ${props =>
    props.hasIcon ? "8px 12px 0px 48px" : "8px 12px 0px 12px"};

  display: ${props => (props.block ? "block" : "inline-block")};

  &::placeholder {
    color: ${props => props.theme.color.black};
    opacity: 0.5;
  }

  &:focus ~ ${InputUnderline} {
    height: 100%;
  }

  &:focus ~ ${Label} {
    transform: translateY(-110%) scale(0.7);
    opacity: 0.8;
  }
`

const Icon = styled.div`
  height: 100%;
  display: inline-flex;
  position: absolute;
  left: 8px;
  top: 3px;
  bottom: 0;
  font-size: 22px;
  align-items: center;
`

const Styled = {
  Wrapper,
  InputUnderline,
  Label,
  InputElement,
  Icon,
}

export default Styled
