import { gql } from "@apollo/client"

export const TOPIC_GROUP_QUERY = gql`
  query getTopicGroup(
    $sort: String!
    $limit: Int
    $offset: Int
    $where: JSON
    $topicGroupsWhere: JSON
  ) {
    articles(sort: $sort, limit: $limit, start: $offset, where: $where) {
      title
      slug
      content
      published_at
    }
    topicGroups(where: $topicGroupsWhere) {
      title
      slug
    }
  }
`

export type TOPIC_GROUP_TYPE_ITEM = {
  title: string
  slug: string
  content: string
  published_at: string
}

export type TOPIC_GROUP_TYPE = {
  articles: TOPIC_GROUP_TYPE_ITEM[]
  topicGroups: {
    title: string
    slug: string
  }[]
}
