import React, { useEffect } from "react"
import ContentWrapper from "components/ContentWrapper"
import PageTransition from "components/PageTransition"
import TripItem from "components/TripItem"
import {
  MobileMenuBarContextType,
  withMobileMenuBarContext,
} from "components/UserMenuBar/MobileMenuBar/MobileMenuBarContext"
import Grid from "components/Grid"
import { useQuery } from "@apollo/client"
import {
  INCOMING_TRIPS_QUERY,
  INCOMING_TRIPS_QUERY_TYPE,
} from "konto/Wyjazdy/Nadchodzące/WyjazdyNadchodzace.gql"
import Spinner from "components/Spinner"
import NoResults from "components/NoResults"
import { parseStringDate } from "tools/parseStringDate"
import Heading from "components/Heading"
import { Helmet } from "react-helmet"

const WyjazdyNadchodzace = ({
  setMobileMenuBarValues,
}: MobileMenuBarContextType) => {
  const { error, loading, data } = useQuery<INCOMING_TRIPS_QUERY_TYPE>(
    INCOMING_TRIPS_QUERY,
    {
      variables: {
        sort: "date:desc",
        where: {
          past: false,
        },
      },
    }
  )

  useEffect(() => {
    setMobileMenuBarValues?.({
      type: "menu",
      title: "Wyjazdy nadchodzące",
    })
  }, [])

  return (
    <>
      <Helmet>
        <title>Nadchodzące wyjazdy | Wspólnota 120</title>
      </Helmet>
      <ContentWrapper paddingTop full>
        <PageTransition>
          <div style={{ marginBottom: "24px" }}>
            <Heading strong>Wyjazdy nadchodzące</Heading>
          </div>
          {data && data.trips.length ? (
            <Grid>
              {data.trips.map(trip => (
                <TripItem
                  key={trip.slug}
                  title={trip.title}
                  place={trip.location}
                  date={`${parseStringDate(trip.from)} – ${parseStringDate(
                    trip.to
                  )}`}
                  url={`/konto/wyjazdy/nadchodzace/${trip.slug}`}
                  image={trip.image?.url}
                />
              ))}
            </Grid>
          ) : loading ? (
            <Spinner size={24} />
          ) : (
            <NoResults>Brak nadchodzących wyjazdów.</NoResults>
          )}
        </PageTransition>
      </ContentWrapper>
    </>
  )
}

export default withMobileMenuBarContext(WyjazdyNadchodzace)
