import styled from "styled-components"

const Wrapper = styled.div`
  position: relative;
`

const Button = styled.button<{ isOpened?: boolean; width?: number }>`
  font-size: 14px;
  height: 36px;
  background: ${props => (props.isOpened ? props.theme.color.white : "none")};
  border-width: 1px;
  border-color: ${props =>
    props.isOpened ? props.theme.color.black : "transparent"};
  outline: none;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${props =>
    props.isOpened ? props.theme.color.black : props.theme.color.dark};
  cursor: pointer;
  border-radius: 6px 6px 0 0;
  padding: 0 12px;
  width: ${props => props.width || 150}px;

  &:hover {
    color: ${props => props.theme.color.black};
  }
`

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  font-size: 20px;
  line-height: 1;
`

const Select = styled.div<{ width?: number }>`
  position: absolute;
  left: 0;
  top: 100%;
  background-color: ${props => props.theme.color.white};
  padding: 6px 0;
  border-radius: 0 0 6px 6px;
  border: 1px solid ${props => props.theme.color.black};
  border-top: none;
  width: ${props => props.width || 150}px;
  z-index: 400;
`

const SelectItem = styled.div`
  display: flex;
  padding: 0 8px 0 12px;
  height: 28px;
  align-items: center;
  font-size: 14px;
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.color.primaryLighter};
  }
`

const SelectItemIcon = styled.div`
  width: 20px;
  height: 20px;
  font-size: 18px;
  margin-right: 12px;
`

const Styled = {
  Wrapper,
  Button,
  IconWrapper,
  Select,
  SelectItem,
  SelectItemIcon,
}

export default Styled
