import React, { FC } from "react"
import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components"
import Icon from "components/Icon"

const StyledLink = styled(GatsbyLink)<{ white?: boolean }>`
  font-weight: bold;
  display: inline-block;
  position: relative;
  z-index: 1;
  color: #000;
  padding: 0 1em;
  cursor: pointer;
  transition-duration: 0.1s;
  text-decoration: none;

  &:hover,
  &:active {
    &::before {
      height: 1.7em;
    }
  }

  &:active {
    transform: translateY(3px);
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.15em;
    height: 0.8em;
    z-index: -1;
    border-radius: 0.4em;
    background-color: ${props =>
      props.white ? "#fff" : props.theme.color.primaryLight};
    transition-duration: 0.1s;
  }
`

const StyledIcon = styled(Icon)<{ iconPosition?: "left" | "right" }>`
  margin-left: ${props => (props.iconPosition === "right" ? 0.5 : -0.3)}em;
  margin-right: ${props => (props.iconPosition === "right" ? -0.3 : 0.5)}em;
  transform: translateY(0.2em);
  font-size: 1.2em;
`

export type LinkProps = {
  icon?: string
  iconPosition?: "left" | "right"
  to?: string
  href?: string
  onClick?: (e?: React.MouseEvent) => void
  white?: boolean
}

const Link: FC<LinkProps> = ({
  white,
  to,
  onClick,
  icon,
  iconPosition,
  href,
  children,
}) => {
  const linkProps = to
    ? {
        to: to,
      }
    : {
        href: href || "#",
      }
  return (
    <StyledLink
      {...linkProps}
      as={to ? GatsbyLink : "a"}
      onClick={onClick}
      white={white}
    >
      {icon && iconPosition !== "right" && (
        <StyledIcon icon={icon} iconPosition={iconPosition} />
      )}
      {children}
      {icon && iconPosition === "right" && (
        <StyledIcon icon={icon} iconPosition={iconPosition} />
      )}
    </StyledLink>
  )
}

export default Link
