import styled from "styled-components"

const NoResults = styled.div`
  text-align: center;
  margin: 1em;
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
`

export default NoResults
