import PageTransition from "components/PageTransition"
import ContentWrapper from "components/ContentWrapper"
import Link from "components/Link"
import { chevronBack } from "ionicons/icons"
import { useLocation } from "@reach/router"
import Heading from "components/Heading"
import Subheading from "components/Subheading"
import React, { useEffect } from "react"
import Entry from "components/Entry"
import Gallery from "components/Gallery/Gallery"
import {
  MobileMenuBarContextType,
  withMobileMenuBarContext,
} from "components/UserMenuBar/MobileMenuBar/MobileMenuBarContext"
import { ViewContextConsumer } from "context/viewContext"
import { getSlugFromLocation } from "tools/getSlugFromLocation"
import { useQuery } from "@apollo/client"
import { WPIS_QUERY, WPIS_QUERY_TYPE } from "konto/Grupy/Grupa/Wpis/Wpis.gql"
import { parseStringDate } from "tools/parseStringDate"
import config from "config/config"
import Spinner from "components/Spinner"
import { Helmet } from "react-helmet"

const Wpis = ({ setMobileMenuBarValues }: MobileMenuBarContextType) => {
  const location = useLocation()
  const slug = getSlugFromLocation(location)

  const { loading, error, data } = useQuery<WPIS_QUERY_TYPE>(WPIS_QUERY, {
    variables: {
      where: {
        slug: slug,
      },
    },
  })

  useEffect(() => {
    if (data && data.articles[0]) {
      setMobileMenuBarValues!({
        type: "entry",
        title: data.articles[0].title,
        subtitle: `${data.articles[0].topic_group.title}, ${parseStringDate(
          data.articles[0].published_at
        )}`,
        backUrl: `/konto/grupy-tematyczne/${data.articles[0].topic_group.slug}`,
      })
    }
  }, [data])

  if (data && data.articles && data.articles[0]) {
    const {
      title,
      content,
      gallery,
      published_at,
      topic_group,
    } = data.articles[0]

    const photos = gallery.map(item => ({
      src: config.uploadPrefix + item.url,
      width: item.width,
      height: item.height,
    }))

    return (
      <>
        <Helmet>
          <title>{title} | Wspólnota 120</title>
        </Helmet>
        <ViewContextConsumer>
          {viewContext => (
            <PageTransition>
              <ContentWrapper>
                <Entry.Header>
                  {(viewContext?.media.md ||
                    viewContext?.media.lg ||
                    viewContext?.media.xl) && (
                    <Link
                      icon={chevronBack}
                      to={`/konto/grupy-tematyczne/${topic_group.slug}`}
                    >
                      Powrót
                    </Link>
                  )}
                  <Heading style={{ marginTop: "10px" }}>{title}</Heading>
                  <Subheading>
                    {topic_group.slug},{" "}
                    {parseStringDate(data.articles[0].published_at)}
                  </Subheading>
                </Entry.Header>
                <Entry.Section>
                  <div
                    style={{ marginBottom: "12px" }}
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </Entry.Section>
                <Entry.Section>
                  <div style={{ marginBottom: "12px" }}>
                    <Gallery images={photos} />
                  </div>
                </Entry.Section>
              </ContentWrapper>
            </PageTransition>
          )}
        </ViewContextConsumer>
      </>
    )
  } else if (loading) {
    return (
      <PageTransition>
        <ContentWrapper>
          <Spinner size={32} />
        </ContentWrapper>
      </PageTransition>
    )
  } else if (error) {
    return (
      <>
        <div></div>
      </>
    )
  }
  return null
}

export default withMobileMenuBarContext(Wpis)
