import Widget, { WidgetSection } from "components/Widget"
import Row from "components/Row"
import DatePicker from "components/DatePicker"
import Filter from "components/Filter"
import Styled from "./FiltersWidget.styled"
import React from "react"
import { FilterOption, FilterOptions } from "components/Filter/Filter"

export type FiltersWidgetProps = {
  startDate: Date | null
  endDate: Date | null
  setStartDate: (date: Date | null) => void
  setEndDate: (date: Date | null) => void
  selectedFilter: FilterOption
  filterOptions: FilterOptions
  past?: boolean
  setSelectedFilter: (option: FilterOption) => void
  fixed?: boolean
}

const FiltersWidget = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  selectedFilter,
  filterOptions,
  setSelectedFilter,
  past,
  fixed,
}: FiltersWidgetProps) => {
  return (
    <Styled.Widget fixed={fixed}>
      <Widget noRadius={fixed}>
        <WidgetSection style={{ padding: "4px" }}>
          <Row justifyContent={"flex-end"}>
            <Filter
              currentOption={selectedFilter}
              options={filterOptions}
              handleSelectFn={setSelectedFilter}
              width={128}
            />
            <DatePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              past={past}
            />
          </Row>
        </WidgetSection>
      </Widget>
    </Styled.Widget>
  )
}

export default FiltersWidget
