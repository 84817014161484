import styled from "styled-components"

const Grid = styled.div`
  display: grid;
  grid-template-rows: repeat(200px);
  grid-template-columns: 1fr;
  grid-gap: 24px;

  ${props => props.theme.media.sm} {
    grid-template-columns: 1fr 1fr;
  }

  ${props => props.theme.media.md} {
    grid-template-columns: 1fr;
  }

  ${props => props.theme.media.lg} {
    grid-template-columns: 1fr 1fr;
  }

  ${props => props.theme.media.xl} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  & > div {
    align-self: stretch;
  }
`

export default Grid
