// Based on: https://codepen.io/supah/pen/BjYLdW

import React from "react"
import styled from "styled-components"

const Wrapper = styled.svg<{ size: number }>`
  animation: rotate 2s linear infinite;
  z-index: 2;
  margin: auto;
  display: block;
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`

const Circle = styled.circle<{ size: number }>`
  stroke: #000;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;

  @keyframes dash {
    0% {
      stroke-dasharray: 1, ${props => props.size * 3};
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: ${props => props.size * 2}, ${props => props.size * 3};
      stroke-dashoffset: ${props => (-1 * props.size) / 2};
    }
    100% {
      stroke-dasharray: ${props => props.size * 2}, ${props => props.size * 3};
      stroke-dashoffset: ${props => -2.3 * props.size};
    }
  }
`

type Props = {
  size: number
}

const Spinner = ({ size }: Props) => (
  <Wrapper viewBox={`0 0 ${size} ${size}`} size={size}>
    <Circle
      cx={size / 2}
      cy={size / 2}
      r={(size / 8) * 3}
      fill="none"
      strokeWidth={2}
      size={size}
    ></Circle>
  </Wrapper>
)

export default Spinner
