import styled from "styled-components"

const SideWrapper = styled.div`
  position: fixed;
  top: 78px;
  right: 24px;
  min-width: 330px;
  max-width: 440px;
  width: calc(100vw - 1105px);
  height: calc(100vh - 108px);
`

export default SideWrapper
