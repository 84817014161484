import React, { useEffect, useRef } from "react"
import Label from "components/Label"
import Croppie from "croppie"
import Button from "components/Button"
import "croppie/croppie.css"
import Modal from "components/Modal"

export type CropModalProps = {
  image: File
  setImage: (image: Blob | null) => void
  setImageToCrop: (image: null) => void
}

const CropModal = ({ image, setImage, setImageToCrop }: CropModalProps) => {
  const croppieRef = useRef<HTMLDivElement>(null)
  let croppie: Croppie

  useEffect(() => {
    if (croppieRef.current) {
      croppie = new Croppie(croppieRef.current, {
        enableExif: true,
        enableZoom: true,
        viewport: {
          width: 250,
          height: 250,
          type: "circle",
        },
        showZoomer: true,
      })
      croppie.bind({
        url: URL.createObjectURL(image),
      })
    }

    return () => croppie.destroy()
  }, [croppieRef])

  const handleSubmit = () => {
    croppie
      .result({
        type: "blob",
        size: {
          width: 256,
          height: 256,
        },
        format: "jpeg",
        quality: 0.9,
        circle: false,
      })
      .then(res => {
        setImage(res)
        setImageToCrop(null)
      })
  }

  const handleCancel = () => {
    setImage(null)
    setImageToCrop(null)
  }

  return (
    <Modal>
      <Label black>Dostosuj zdjęcie</Label>
      <div
        style={{
          width: "100%",
          margin: "12px 0 64px 0",
        }}
      >
        <div style={{ height: "400px" }} ref={croppieRef}></div>
      </div>
      <Button block style={{ marginBottom: "16px" }} onClick={handleSubmit}>
        Akceptuj
      </Button>
      <Button block gray onClick={handleCancel}>
        Anuluj
      </Button>
    </Modal>
  )
}

export default CropModal
