import Widget from "components/Widget"
import WidgetSection from "components/Widget/WidgetSection"
import GalleryPortal from "components/GalleryPortal"
import React, { FC } from "react"
import Styled from "./Modal.styled"

const Modal: FC = ({ children }) => {
  return (
    <GalleryPortal>
      <Styled.Overlay />
      <Styled.ModalWrapper>
        <Styled.Modal>
          <Widget white>
            <WidgetSection>{children}</WidgetSection>
          </Widget>
        </Styled.Modal>
      </Styled.ModalWrapper>
    </GalleryPortal>
  )
}

export default Modal
