import { parseDateForGql } from "tools/parseDateForGql"

export const parseDateRangeForGql = (
  startDate: Date | null,
  endDate: Date | null
): [string | undefined, string | undefined] => {
  const today = new Date()
  const dateGt =
    startDate && endDate && startDate.getTime() <= today.getTime()
      ? parseDateForGql(startDate) + "T00:00:00.000Z"
      : undefined
  const dateLt =
    startDate && endDate && endDate.getTime() <= today.getTime()
      ? parseDateForGql(endDate) + "T23:59:59.999Z"
      : parseDateForGql(today) + "T23:59:59.999Z"
  return [dateGt, dateLt]
}
