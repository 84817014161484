import React, { useEffect, useMemo, useState } from "react"
import ContentWrapper from "components/ContentWrapper"
import PageTransition from "components/PageTransition"
import TripItem from "components/TripItem"
import {
  MobileMenuBarContextType,
  withMobileMenuBarContext,
} from "components/UserMenuBar/MobileMenuBar/MobileMenuBarContext"
import Grid from "components/Grid"
import {
  FilterOption,
  FilterOptions,
  filterOptionsNewestOldest,
} from "components/Filter/Filter"
import Row from "components/Row"
import { useQuery } from "@apollo/client"
import { parseDateRangeForGql } from "tools/parseDateRangeForGql"
import {
  PAST_TRIPS_QUERY,
  PAST_TRIPS_QUERY_TYPE,
  PAST_TRIPS_QUERY_TYPE_ITEM,
} from "konto/Wyjazdy/Minione/WyjazdyMinione.gql"
import { parseStringDate } from "tools/parseStringDate"
import { ViewContextConsumer } from "context/viewContext"
import FiltersWidget from "components/FiltersWidget"
import Heading from "components/Heading"
import { Helmet } from "react-helmet"

const WyjazdyMinione = ({
  setMobileMenuBarValues,
}: MobileMenuBarContextType) => {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [searchPhrase, setSearchPhrase] = useState("")

  const filterOptions: FilterOptions = filterOptionsNewestOldest

  const [selectedFilter, setSelectedFilter] = useState<FilterOption>(
    filterOptions.newest
  )

  const [page, setPage] = useState(0)
  const [completed, setCompleted] = useState(false)
  const [loading, setLoading] = useState(true)
  const [moreResults, setMoreResults] = useState<PAST_TRIPS_QUERY_TYPE_ITEM[]>(
    []
  )

  const [queryVariables, setQueryVariables] = useState<{
    sort: string
    limit: number
    where?: {
      past: true
      _or?: { [key: string]: string | undefined }[]
    }
  }>({
    sort: selectedFilter.value,
    limit: 10,
    where: {
      past: true,
    },
  })

  const { error, data, refetch, fetchMore } = useQuery<PAST_TRIPS_QUERY_TYPE>(
    PAST_TRIPS_QUERY,
    {
      variables: queryVariables,
    }
  )

  const results = useMemo(
    () => (data && data.trips ? [...data.trips, ...moreResults] : []),
    [data, moreResults]
  )

  useEffect(() => {
    const [dateGt, dateLt] = parseDateRangeForGql(startDate, endDate)

    setLoading(true)
    setQueryVariables({
      sort: selectedFilter.value,
      limit: 10,
      where: {
        past: true,
        _or: searchPhrase.length
          ? [
              { title_contains: searchPhrase },
              { location_contains: searchPhrase },
              {
                to_gt: dateGt,
                to_lt: dateLt,
              },
              {
                from_gt: dateGt,
                from_lt: dateLt,
              },
            ]
          : [
              {
                to_gt: dateGt,
                to_lt: dateLt,
              },
              {
                from_gt: dateGt,
                from_lt: dateLt,
              },
            ],
      },
    })

    setPage(0)
    setCompleted(false)
    setMoreResults([])
  }, [startDate, endDate, searchPhrase, selectedFilter])

  useEffect(() => {
    refetch(queryVariables).then(() => {
      setLoading(false)
    })
  }, [queryVariables])

  useEffect(() => {
    if (setMobileMenuBarValues) {
      setMobileMenuBarValues({
        type: "menu",
        title: "Wyjazdy minione",
      })
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Minione wyjazdy | Wspólnota 120</title>
      </Helmet>
      <ViewContextConsumer>
        {viewContext => (
          <PageTransition>
            {(viewContext?.media.xs || viewContext?.media.sm) && (
              <FiltersWidget
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                selectedFilter={selectedFilter}
                filterOptions={filterOptions}
                setSelectedFilter={setSelectedFilter}
                fixed
              />
            )}
            <ContentWrapper paddingTop full>
              {(viewContext?.media.md ||
                viewContext?.media.lg ||
                viewContext?.media.xl) && (
                <div style={{ marginBottom: "24px" }}>
                  <Row flexWrap>
                    <Heading strong>Wyjazdy minione</Heading>

                    <FiltersWidget
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      selectedFilter={selectedFilter}
                      filterOptions={filterOptions}
                      setSelectedFilter={setSelectedFilter}
                    />
                  </Row>
                </div>
              )}
              <Grid>
                {results.map(trip => (
                  <TripItem
                    key={trip.slug}
                    title={trip.title}
                    place={trip.location || ""}
                    date={
                      trip.from &&
                      trip.to &&
                      (trip.from === trip.to
                        ? parseStringDate(trip.from)
                        : `${parseStringDate(trip.from)} – ${parseStringDate(
                            trip.to
                          )}`)
                    }
                    url={`/konto/wyjazdy/minione/${trip.slug}`}
                  />
                ))}
              </Grid>
            </ContentWrapper>
          </PageTransition>
        )}
      </ViewContextConsumer>
    </>
  )
}

export default withMobileMenuBarContext(WyjazdyMinione)
