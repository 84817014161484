import { MobileMenuBarContextType } from "components/UserMenuBar/MobileMenuBar/MobileMenuBarContext"
import React, { useMemo, useState } from "react"
import {
  FilterOption,
  FilterOptions,
  filterOptionsNewestOldest,
} from "components/Filter/Filter"
import PageTransition from "components/PageTransition"
import FiltersWidget from "components/FiltersWidget"
import ContentWrapper from "components/ContentWrapper"
import Row from "components/Row"
import Heading from "components/Heading"
import { ViewContextConsumer } from "context/viewContext"
import loadable from "@loadable/component"
import { parseDateRangeForGql } from "tools/parseDateRangeForGql"

const Zadania = loadable(() => import("konto/Zadania"))
const Lektury = loadable(() => import("konto/Lektury"))
const Konferencje = loadable(() => import("konto/Konferencje"))
const Pytania = loadable(() => import("konto/Pytania"))
const Katechizm = loadable(() => import("konto/Katechizm"))
const Prezentacje = loadable(() => import("konto/Prezentacje"))
const Kazania = loadable(() => import("konto/Kazania"))

export const getSpotkaniaElementQueryVariables = (
  startDate: Date | null,
  endDate: Date | null,
  selectedFilter: FilterOption
) => {
  const [dateGt, dateLt] = parseDateRangeForGql(startDate, endDate)
  return {
    sort: selectedFilter.value,
    limit: 10,
    offset: 0,
    where: {
      date_lt: dateLt,
      date_gt: dateGt,
    },
  }
}

const SpotkaniaElementy = ({
  setMobileMenuBarValues,
  element,
}: MobileMenuBarContextType & { element: string }) => {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [title, setTitle] = useState("")

  const filterOptions: FilterOptions = filterOptionsNewestOldest

  const [selectedFilter, setSelectedFilter] = useState<FilterOption>(
    filterOptions.newest
  )

  const queryVariables = useMemo(
    () => getSpotkaniaElementQueryVariables(startDate, endDate, selectedFilter),
    [startDate, endDate, selectedFilter]
  )

  return (
    <ViewContextConsumer>
      {viewContext => (
        <PageTransition absolute>
          {(viewContext?.media.xs || viewContext?.media.sm) && (
            <FiltersWidget
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              selectedFilter={selectedFilter}
              filterOptions={filterOptions}
              setSelectedFilter={setSelectedFilter}
              fixed
              past
            />
          )}
          <ContentWrapper paddingTop>
            {(viewContext?.media.md ||
              viewContext?.media.lg ||
              viewContext?.media.xl) && (
              <Row flexWrap>
                <Heading strong>{title}</Heading>

                <FiltersWidget
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  selectedFilter={selectedFilter}
                  filterOptions={filterOptions}
                  setSelectedFilter={setSelectedFilter}
                  past
                />
              </Row>
            )}

            {element === "konferencje" && (
              <Konferencje
                queryVariables={queryVariables}
                setMobileMenuBarValues={setMobileMenuBarValues}
                setTitle={setTitle}
                path={"/"}
              />
            )}
            {element === "katechizm" && (
              <Katechizm
                queryVariables={queryVariables}
                setMobileMenuBarValues={setMobileMenuBarValues}
                setTitle={setTitle}
                path={"/"}
              />
            )}
            {element === "pytania" && (
              <Pytania
                queryVariables={queryVariables}
                setMobileMenuBarValues={setMobileMenuBarValues}
                setTitle={setTitle}
                path={"/"}
              />
            )}
            {element === "zadania" && (
              <Zadania
                queryVariables={queryVariables}
                setMobileMenuBarValues={setMobileMenuBarValues}
                setTitle={setTitle}
                path={"/"}
              />
            )}
            {element === "lektury" && (
              <Lektury
                queryVariables={queryVariables}
                setMobileMenuBarValues={setMobileMenuBarValues}
                setTitle={setTitle}
                path={"/"}
              />
            )}
            {element === "prezentacje" && (
              <Prezentacje
                queryVariables={queryVariables}
                setMobileMenuBarValues={setMobileMenuBarValues}
                setTitle={setTitle}
                path={"/"}
              />
            )}
            {element === "kazania" && (
              <Kazania
                queryVariables={queryVariables}
                setMobileMenuBarValues={setMobileMenuBarValues}
                setTitle={setTitle}
                path={"/"}
              />
            )}
          </ContentWrapper>
        </PageTransition>
      )}
    </ViewContextConsumer>
  )
}

export default SpotkaniaElementy
