import Widget, { WidgetImage, WidgetSection } from "components/Widget"
import Heading from "components/Heading"
import Label from "components/Label"
import Button from "components/Button"
import React from "react"
import { Link } from "gatsby"
import placeholder from "assets/images/placeholder-4-3.png"
import config from "config/config"

type TripItemType = {
  title: string
  place?: string
  date?: string
  url: string
  image?: string
}

const TripItem = ({ title, place, date, url, image }: TripItemType) => {
  return (
    <Widget gray>
      <WidgetImage src={image ? config.uploadPrefix + image : placeholder} />
      <WidgetSection style={{ marginBottom: "8px" }}>
        <Heading level={3} style={{ margin: "0 0 8px 0" }}>
          {title}
        </Heading>
        {(place || date) && (
          <Label>
            {place}
            {place && date && ", "}
            {date}
          </Label>
        )}
      </WidgetSection>
      <Button as={Link} to={url} block>
        Więcej
      </Button>
    </Widget>
  )
}

export default TripItem
