import styled from "styled-components"

const GroupColumn = styled.div`
  width: 50%;
  flex-shrink: 0;
  margin-bottom: 16px;

  ${props => props.theme.media.sm} {
    width: 33%;
  }

  ${props => props.theme.media.lg} {
    width: 25%;
  }
`

const CookieColumn = styled.div`
  width: 100%;
  flex-shrink: 0;
  margin-bottom: 16px;

  ${props => props.theme.media.sm} {
    width: 50%;
  }
`

const Participants = styled.div``

const Participant = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: center;
  height: 42px;
  padding: 0 16px;

  &:nth-child(1) {
    font-weight: bold;
  }

  &:nth-of-type(2n + 3) {
    background-color: rgba(0, 0, 0, 0.03);
  }

  &:nth-of-type(n + 2):hover {
    box-shadow: 0 0 0 1.5px rgba(0, 0, 0, 0.2) inset;
  }
`

const ParticipantName = styled.div`
  flex-grow: 1;
`

const ParticipantGroup = styled.div`
  width: 64px;
  text-align: center;
`

const Styled = {
  GroupColumn,
  Participants,
  Participant,
  ParticipantName,
  ParticipantGroup,
  CookieColumn,
}

export default Styled
