import React, { Dispatch, FC, SetStateAction, useState } from "react"

export type MobileMenuBarContextValuesType = {
  type: "menuLogo" | "menu" | "entry"
  title?: string
  subtitle?: string
  backUrl?: string
}

export type MobileMenuBarContextType = {
  mobileMenuBarValues: MobileMenuBarContextValuesType
  setMobileMenuBarValues?: Dispatch<
    SetStateAction<MobileMenuBarContextValuesType>
  >
}

const MobileMenuBarContext = React.createContext<MobileMenuBarContextType>({
  mobileMenuBarValues: {
    type: "menuLogo",
  },
})

const MobileMenuBarContextProvider: FC = ({ children }) => {
  const [values, setValues] = useState<MobileMenuBarContextValuesType>({
    type: "menuLogo",
  })

  return (
    <MobileMenuBarContext.Provider
      value={{ mobileMenuBarValues: values, setMobileMenuBarValues: setValues }}
    >
      {children}
    </MobileMenuBarContext.Provider>
  )
}

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>

const withMobileMenuBarContext = <P extends object>(
  Component: React.ComponentType<P>
): FC<Omit<P, keyof MobileMenuBarContextType>> => props => (
  <MobileMenuBarContext.Consumer>
    {({ mobileMenuBarValues, setMobileMenuBarValues }) => (
      <Component
        {...(props as P)}
        mobileMenuBarValues={mobileMenuBarValues}
        setMobileMenuBarValues={setMobileMenuBarValues}
      />
    )}
  </MobileMenuBarContext.Consumer>
)

export {
  MobileMenuBarContext,
  MobileMenuBarContextProvider,
  withMobileMenuBarContext,
}
