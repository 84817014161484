import styled from "styled-components"

const SectionTitle = styled.h2<{ withLine?: boolean }>`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  margin: 16px 0;
  justify-content: stretch;

  &:first-child {
    margin-top: 0;
  }

  ${props =>
    props.withLine &&
    `&::before {
    position: absolute;
    content: "";
    display: block;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    left: -42px;
    width: 30px;
    background-color: #000;
    border-radius: 0 2px 2px 0;
  }`}
`

export default SectionTitle
