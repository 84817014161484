import { gql } from "@apollo/client"

export const SONGS_QUERY = gql`
  query {
    piosenkis {
      title
      slug
    }
  }
`
export type SongType = {
  title: string
  slug: string
}

export type SONGS_QUERY_TYPE = {
  piosenkis: SongType[]
}
