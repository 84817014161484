import styled from "styled-components"

const Separator = styled.hr<{ white?: boolean }>`
  border: none;
  height: 2px;
  margin: 0;
  background-color: ${props => {
    if (props.white) return "#fff"
  }};
`

export default Separator
