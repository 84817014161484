import React, { useEffect, useMemo, useRef, useState } from "react"
import Input from "components/Input"
import Button from "components/Button"
import { gql, useMutation, useQuery } from "@apollo/client"
import Widget from "components/Widget"
import WidgetSection from "components/Widget/WidgetSection"
import Label from "components/Label"
import Styled from "konto/Ustawienia/Ustawienia.styled"
import config from "config/config"
import defaultAvatar from "assets/images/defaultAvatarLarge.png"
import IconButton from "components/IconButton"
import Icon from "components/Icon"
import { closeOutline } from "ionicons/icons"
import Spinner from "components/Spinner"
import CropModal from "components/CropModal/CropModal"
import Alert from "components/Alert"

const IMAGE_UPLOAD_MUTATION = gql`
  mutation uploadFile($file: Upload!) {
    upload(file: $file) {
      name
      url
      id
    }
  }
`

const UPDATE_ME_MUTATION = gql`
  mutation updateMe(
    $userId: ID!
    $name: String
    $surname: String
    $username: String!
    $email: String!
    $image: String!
  ) {
    updateUser(
      input: {
        where: { id: $userId }
        data: {
          username: $username
          email: $email
          name: $name
          surname: $surname
          image: $image
        }
      }
    ) {
      user {
        username
        email
        name
        surname
        image
      }
    }
  }
`

const ME_QUERY = gql`
  query {
    me {
      id
      username
      email
      name
      surname
      image
    }
  }
`

type ME_QUERY_TYPE = {
  me: {
    id: string
    name?: string
    surname?: string
    image?: string
    email?: string
  }
}

const DataForm = () => {
  const { loading, error, data, refetch } = useQuery<ME_QUERY_TYPE>(ME_QUERY)

  const fileRef = useRef<HTMLInputElement | null>(null)
  const [updateMe] = useMutation(UPDATE_ME_MUTATION)
  const [uploadImage] = useMutation(IMAGE_UPLOAD_MUTATION)

  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [image, setImage] = useState<Blob | null | "remove">(null)

  const [imageToCrop, setImageToCrop] = useState<File | null>(null)
  const [loadingButton, setLoadingButton] = useState<boolean>(false)

  const [submitError, setSubmitError] = useState("")
  const [submitSuccess, setSubmitSuccess] = useState("")

  useEffect(() => {
    if (data && data.me) {
      if (data.me.email) setEmail(data.me.email)
      if (data.me.name) setName(data.me.name)
      if (data.me.surname) setSurname(data.me.surname)
    }
  }, [data])

  const saveActive = useMemo(
    () =>
      data &&
      data.me &&
      email.length &&
      name.length &&
      surname.length &&
      (image !== null ||
        email !== data.me.email ||
        name !== data.me.name ||
        surname !== data.me.surname),
    [email, name, surname, image, data]
  )

  const handleImageAdd = () => {
    if (fileRef.current) {
      fileRef.current.click()
    }
  }

  const handleImageChange = () => {
    if (
      fileRef.current &&
      fileRef.current.files &&
      fileRef.current.files.length
    ) {
      const file = fileRef.current.files[0]
      setImageToCrop(file)
    }
  }

  const handleRemoveImage = () => {
    if (data && data.me) {
      if (data.me.image) {
        setImage("remove")
      } else {
        setImage(null)
      }
    }
  }

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault()

    if (saveActive && data && data.me) {
      setSubmitError("")
      setSubmitSuccess("")
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        )
      ) {
        setSubmitError("Proszę podać poprawny adres e-mail")
        return
      }

      setLoadingButton(true)
      if (image && image !== "remove") {
        uploadImage({
          variables: {
            file: image,
          },
        })
          .then(res => {
            if (res.data) {
              updateMe({
                variables: {
                  userId: data.me.id,
                  name: name,
                  surname: surname,
                  email: email,
                  username: email,
                  image: res.data.upload.url,
                },
              })
                .then(res => {
                  if (res.data) {
                    setSubmitError("")
                    setSubmitSuccess("Dane zostały zapisane.")
                    refetch().then(res => setLoadingButton(false))
                  }
                })
                .catch(res => {
                  setSubmitError(
                    "Nie udało się zapisać danych. Proszę spróbować później."
                  )
                  setLoadingButton(false)
                })
            }
          })
          .catch(err => {
            setSubmitError("Wystąpił problem z przesłaniem zdjęcia.")
            setLoadingButton(false)
          })
      } else {
        updateMe({
          variables: {
            userId: data.me.id,
            name: name,
            surname: surname,
            email: email,
            username: email,
            image: image === "remove" ? "" : data.me.image,
          },
        }).then(res => {
          if (res.data) {
            setSubmitSuccess("Dane zostały zapisane.")
            refetch().then(res => setLoadingButton(false))
          }
        })
      }
    }
  }

  return (
    <>
      <Widget white margin>
        <WidgetSection>
          <Label black>O mnie</Label>
          {loading ? (
            <Spinner size={24} />
          ) : (
            data && (
              <>
                <Styled.UserDataWrapper>
                  <div style={{ marginBottom: "16px" }}>
                    <div>
                      <Input
                        block
                        label={"Adres e-mail"}
                        value={email}
                        onChange={e => setEmail(e.currentTarget.value)}
                      />
                      <Input
                        block
                        label={"Imię"}
                        value={name}
                        onChange={e => setName(e.currentTarget.value)}
                      />
                      <Input
                        block
                        label={"Nazwisko"}
                        value={surname}
                        onChange={e => setSurname(e.currentTarget.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <Styled.UserImageWrapper>
                      <Styled.UserImage
                        backgroundImage={
                          image && image !== "remove"
                            ? URL.createObjectURL(image)
                            : data.me.image &&
                              data.me.image.length &&
                              image !== "remove"
                            ? `${config.uploadPrefix}${data.me.image}`
                            : defaultAvatar
                        }
                      >
                        <Styled.UserImageInput
                          ref={fileRef}
                          type={"file"}
                          onChange={handleImageChange}
                          accept={"image/png, image/jpeg"}
                        />
                        <Styled.UserImageAdd onClick={handleImageAdd}>
                          Prześlij zdjęcie
                        </Styled.UserImageAdd>

                        {image !== "remove" && (image || data.me.image) && (
                          <Styled.UserImageDelete onClick={handleRemoveImage}>
                            <IconButton noMargin>
                              <Icon icon={closeOutline} />
                            </IconButton>
                          </Styled.UserImageDelete>
                        )}
                      </Styled.UserImage>
                    </Styled.UserImageWrapper>
                  </div>
                </Styled.UserDataWrapper>

                <div style={{ marginBottom: "24px", marginTop: "0px" }}>
                  <Alert isActive={!!submitError.length} error>
                    {submitError}
                  </Alert>
                  <Alert isActive={!!submitSuccess.length} success>
                    {submitSuccess}
                  </Alert>
                </div>

                <Button
                  disabled={!saveActive}
                  onClick={handleSubmit}
                  style={{ width: "120px" }}
                >
                  {loadingButton ? <Spinner size={24} /> : "Zapisz"}
                </Button>
              </>
            )
          )}
        </WidgetSection>
      </Widget>
      {imageToCrop && (
        <CropModal
          image={imageToCrop}
          setImage={setImage}
          setImageToCrop={setImageToCrop}
        />
      )}
    </>
  )
}

export default DataForm
