import styled from "styled-components"

const DatePickerWrapper = styled.div`
  position: absolute;
  top: 80%;
  right: 0;
  z-index: 1000;
`

const DatePickerLabel = styled.div`
  background-color: ${props => props.theme.color.primaryLight};
  margin-left: 12px;
  padding: 0 4px 0 8px;
  font-size: 12px;
  height: 24px;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  line-height: 1;
`

const DatePickerLabelClear = styled.button`
  border: none;
  padding: 0;
  margin: 0 0 0 4px;
  background: none;
  line-height: 1;
  font-size: 16px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.3);

  &:hover {
    color: rgba(0, 0, 0, 0.6);
  }

  &:active {
    color: rgba(0, 0, 0, 0.9);
  }
`

const DatePickerButton = styled.button<{ isOpened?: boolean }>`
  font-size: 24px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  margin: 0 8px;
  outline: none;
  cursor: pointer;
  color: ${props =>
    props.isOpened ? props.theme.color.black : props.theme.color.dark};

  &:hover {
    color: #000;
  }
`

const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
  align-items: center;
`

const Styled = {
  DatePickerButton,
  DatePickerLabelClear,
  DatePickerLabel,
  DatePickerWrapper,
  Wrapper,
}

export default Styled
