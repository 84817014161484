import ReactDatePicker from "react-datepicker"
import React, { useState } from "react"
import "./DatePicker.scss"
import Styled from "./DatePicker.styled"
import { calendarOutline, close } from "ionicons/icons"
import Icon from "components/Icon"

type DatePickerProps = {
  startDate: Date | null
  endDate: Date | null
  setStartDate: (date: Date | null) => void
  setEndDate: (date: Date | null) => void
  past?: boolean
}

const DatePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  past,
}: DatePickerProps) => {
  const [isDatepickerOpened, setIsDatepickerOpened] = useState(false)
  const handleDatepickerToggle = () => {
    setIsDatepickerOpened(!isDatepickerOpened)
  }

  const handleDateChange = (date: Date | Date[] | null) => {
    if (date && Array.isArray(date)) {
      const [start, end] = date
      setStartDate(start)
      setEndDate(end)
      if (end) setIsDatepickerOpened(false)
    }
  }

  const handleDatepickerClear = () => {
    setStartDate(null)
    setEndDate(null)
  }

  const filterDates = (date: Date) => {
    if (past) {
      const currentDate = new Date()
      const selectedDate = new Date(date)

      return currentDate.getTime() >= selectedDate.getTime()
    } else {
      return true
    }
  }

  return (
    <Styled.Wrapper>
      {startDate && endDate && (
        <Styled.DatePickerLabel>
          {`${startDate.toLocaleDateString()} – ${endDate.toLocaleDateString()}`}
          <Styled.DatePickerLabelClear onClick={handleDatepickerClear}>
            <Icon icon={close} />
          </Styled.DatePickerLabelClear>
        </Styled.DatePickerLabel>
      )}

      <Styled.DatePickerButton
        isOpened={isDatepickerOpened}
        onClick={handleDatepickerToggle}
      >
        <Icon icon={calendarOutline} />
      </Styled.DatePickerButton>
      {isDatepickerOpened && (
        <Styled.DatePickerWrapper>
          <ReactDatePicker
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            filterDate={filterDates}
            selectsRange
            disabledKeyboardNavigation
            inline
          />
        </Styled.DatePickerWrapper>
      )}
    </Styled.Wrapper>
  )
}

export default DatePicker
