import React, { FC } from "react"
import Collapse from "components/Collapse"
import Styled from "./Alert.styled"

export type AlertProps = {
  error?: boolean
  success?: boolean
  isActive: boolean
}

const Alert: FC<AlertProps> = ({ isActive, error, success, children }) => {
  return (
    <Collapse isCollapsed={!isActive}>
      <Styled.Wrapper error={error} success={success}>
        {children}
      </Styled.Wrapper>
    </Collapse>
  )
}

export default Alert
