import ContentWrapper from "components/ContentWrapper"
import PageTransition from "components/PageTransition"
import React, { useEffect } from "react"

import { ViewContextConsumer } from "context/viewContext"
import {
  MobileMenuBarContextType,
  withMobileMenuBarContext,
} from "components/UserMenuBar/MobileMenuBar/MobileMenuBarContext"
import Heading from "components/Heading"
import Widget, { WidgetImage } from "components/Widget"
import Grid from "components/Grid"
import { useQuery } from "@apollo/client"
import {
  TOPIC_GROUPS_QUERY,
  TOPIC_GROUPS_QUERY_TYPE,
} from "konto/Grupy/Grupy.gql"
import Spinner from "components/Spinner"
import NoResults from "components/NoResults"
import config from "config/config"
import { Helmet } from "react-helmet"

const Grupy = ({ setMobileMenuBarValues }: MobileMenuBarContextType) => {
  const { loading, error, data } = useQuery<TOPIC_GROUPS_QUERY_TYPE>(
    TOPIC_GROUPS_QUERY
  )

  useEffect(() => {
    setMobileMenuBarValues!({
      type: "menu",
      title: "Grupy tematyczne",
    })
  }, [])

  return (
    <>
      <Helmet>
        <title>Grupy tematyczne | Wspólnota 120</title>
      </Helmet>
      <ViewContextConsumer>
        {viewContext => (
          <PageTransition>
            <ContentWrapper paddingTop full>
              {(viewContext?.media.md ||
                viewContext?.media.lg ||
                viewContext?.media.xl) && (
                <Heading strong>Grupy tematyczne</Heading>
              )}
              <div style={{ marginTop: "24px" }}>
                {loading ? (
                  <Spinner size={32} />
                ) : data && data.topicGroups?.length ? (
                  <Grid>
                    {data.topicGroups.map(group => (
                      <Widget key={group.slug}>
                        <WidgetImage
                          src={`${config.uploadPrefix}${group.image?.url}`}
                          overlay
                          title={group.title}
                          link
                          to={"/konto/grupy-tematyczne/" + group.slug}
                        />
                      </Widget>
                    ))}
                  </Grid>
                ) : (
                  <NoResults>Brak grup tematycznych</NoResults>
                )}
              </div>
            </ContentWrapper>
          </PageTransition>
        )}
      </ViewContextConsumer>
    </>
  )
}

export default withMobileMenuBarContext(Grupy)
