import React, { useEffect } from "react"
import {
  MobileMenuBarContextType,
  withMobileMenuBarContext,
} from "components/UserMenuBar/MobileMenuBar/MobileMenuBarContext"
import { ViewContextConsumer } from "context/viewContext"
import PageTransition from "components/PageTransition"
import ContentWrapper from "components/ContentWrapper"
import Entry from "components/Entry"
import Link from "components/Link"
import {
  chevronBack,
  downloadOutline,
  musicalNoteOutline,
  musicalNotesOutline,
  readerOutline,
  videocamOutline,
} from "ionicons/icons"
import Heading from "components/Heading"
import SectionTitle from "components/SectionTitle"
import WIcon from "components/WIcon"
import Row from "components/Row"
import AudioPlayer from "components/AudioPlayer"
import { useQuery } from "@apollo/client"
import {
  SONG_QUERY,
  SONG_QUERY_TYPE,
} from "konto/Spiewnik/Piosenka/Piosenka.gql"
import { useLocation } from "@reach/router"
import Spinner from "components/Spinner"
import { getYoutubeIdFromUrl } from "tools/getYoutubeIdFromUrl"
import ResponsiveEmbed from "components/ResponsiveEmbed"
import config from "config/config"
import { Helmet } from "react-helmet"

const Piosenka = ({ setMobileMenuBarValues }: MobileMenuBarContextType) => {
  const location = useLocation()
  const slug = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  )
  const { loading, error, data } = useQuery<SONG_QUERY_TYPE>(SONG_QUERY, {
    variables: {
      where: {
        slug: slug,
      },
    },
  })
  useEffect(() => {
    setMobileMenuBarValues!({
      type: "entry",
      title: "Chlebie najcichszy",
      subtitle: "śpiewnik",
      backUrl: "/konto/spiewnik",
    })
  }, [])

  const song = data?.piosenkis[0]

  return (
    <>
      <Helmet>{song && <title>song.title | Wspólnota 120</title>}</Helmet>
      <ViewContextConsumer>
        {viewContext => (
          <PageTransition>
            <ContentWrapper>
              {loading && <Spinner size={42} />}
              {song && (
                <>
                  <Entry.Header>
                    {(viewContext?.media.md ||
                      viewContext?.media.lg ||
                      viewContext?.media.xl) && (
                      <Link icon={chevronBack} to={"/konto/spiewnik"}>
                        Powrót
                      </Link>
                    )}
                    <Heading>{song.title}</Heading>
                  </Entry.Header>
                  {(song.lyrics || song.chords) && (
                    <Entry.Section>
                      <SectionTitle withLine>
                        <WIcon icon={readerOutline} />
                        Słowa
                      </SectionTitle>
                      <div style={{ marginBottom: "12px" }}>
                        <Row
                          alignItems={"flex-start"}
                          justifyContent={"flex-start"}
                        >
                          {song.lyrics && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: song.lyrics.replace(
                                  /(?:\r\n|\r|\n)/g,
                                  "<br />"
                                ),
                              }}
                            />
                          )}
                          {song.chords && (
                            <div style={{ paddingLeft: "16px" }}>
                              <strong
                                style={{ display: "block" }}
                                dangerouslySetInnerHTML={{
                                  __html: song.chords.replace(
                                    /(?:\r\n|\r|\n)/g,
                                    "<br />"
                                  ),
                                }}
                              />
                            </div>
                          )}
                        </Row>
                      </div>
                    </Entry.Section>
                  )}
                  {!!song.audio.length && (
                    <Entry.Section>
                      <SectionTitle withLine>
                        <WIcon icon={musicalNotesOutline} />
                        Nagrania
                      </SectionTitle>
                      <div style={{ marginTop: "24px" }}>
                        {song.audio.map((audio, index) => (
                          <div key={index} style={{ margin: "16px 0" }}>
                            <AudioPlayer
                              src={audio.file.url}
                              title={audio.title}
                            />
                          </div>
                        ))}
                      </div>
                    </Entry.Section>
                  )}
                  {!!song.sheets?.length && !!song.sheets[0].file.url.length && (
                    <Entry.Section>
                      <SectionTitle withLine>
                        <WIcon icon={musicalNoteOutline} />
                        Nuty
                      </SectionTitle>
                      <div style={{ marginTop: "24px" }}>
                        {song.sheets.map(
                          (sheet, index) =>
                            !!sheet.file?.url && (
                              <div key={index} style={{ margin: "16px 0" }}>
                                <Link
                                  href={config.uploadPrefix + sheet.file.url}
                                  icon={downloadOutline}
                                >
                                  {sheet.title?.trim() || "Pobierz"}
                                </Link>
                              </div>
                            )
                        )}
                      </div>
                    </Entry.Section>
                  )}
                  {!!song.youtube &&
                    !!song.youtube.length &&
                    !!song.youtube[0].youtube?.length && (
                      <Entry.Section>
                        <SectionTitle withLine>
                          <WIcon icon={videocamOutline} />
                          Filmy
                        </SectionTitle>
                        <div style={{ marginTop: "24px" }}>
                          {song.youtube.map(
                            (youtube, index) =>
                              !!youtube.youtube && (
                                <div key={index} style={{ margin: "16px 0" }}>
                                  <ResponsiveEmbed
                                    src={`https://www.youtube.com/embed/${getYoutubeIdFromUrl(
                                      youtube.youtube
                                    )}`}
                                  />
                                </div>
                              )
                          )}
                        </div>
                      </Entry.Section>
                    )}
                </>
              )}
            </ContentWrapper>
          </PageTransition>
        )}
      </ViewContextConsumer>
    </>
  )
}

export default withMobileMenuBarContext(Piosenka)
