import styled from "styled-components"

const Wrapper = styled.div<{ error?: boolean; success?: boolean }>`
  padding: 12px 16px;
  background-color: ${props =>
    props.error
      ? props.theme.color.error
      : props.success
      ? props.theme.color.success
      : props.theme.color.primaryLight};
  border-radius: 6px;
  color: ${props =>
    props.error || props.success
      ? props.theme.color.white
      : props.theme.color.black};
`

const Styled = {
  Wrapper,
}

export default Styled
