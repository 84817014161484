import styled from "styled-components"

const Widget = styled.div<{ fixed?: boolean }>`
  width: 100%;
  flex-shrink: 0;

  ${props =>
    props.fixed &&
    `
    position: sticky;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 600;
    border-bottom: 1px solid #000;
  `}

  ${props => props.theme.media.md} {
    width: auto;
  }
`
const Styled = {
  Widget,
}

export default Styled
