import React from "react"
import Styled from "./Input.styled"
import Icon from "components/Icon"

export type InputProps = {
  block?: boolean
  type?: "text" | "email" | "tel" | "password"
  icon?: string
  placeholder?: string
  label?: string
  large?: boolean
  id?: string
  value?: string
  required?: boolean
  error?: boolean
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void
}

const Input = ({
  block,
  icon,
  id,
  label,
  large,
  onChange,
  placeholder,
  type,
  value,
  required,
}: InputProps) => {
  return (
    <Styled.Wrapper block={block}>
      {icon && (
        <Styled.Icon>
          <Icon icon={icon} />
        </Styled.Icon>
      )}

      <Styled.InputElement
        id={id}
        placeholder={placeholder}
        block={block}
        hasIcon={!!icon}
        large={large}
        type={type}
        value={value}
        required={required}
        onChange={onChange}
      />
      <Styled.InputUnderline />
      <Styled.Label
        htmlFor={id}
        hasIcon={!!icon}
        isFilled={!!value && value.length > 0}
        required={required}
      >
        {label}
      </Styled.Label>
    </Styled.Wrapper>
  )
}

export default Input
