import React, { useEffect } from "react"
import PageTransition from "components/PageTransition"
import ContentWrapper from "components/ContentWrapper"
import Heading from "components/Heading"
import DataForm from "konto/Ustawienia/DataForm"
import PasswordForm from "konto/Ustawienia/PasswordForm"
import { withMobileMenuBarContext } from "components/UserMenuBar/MobileMenuBar/MobileMenuBarContext"
import { Helmet } from "react-helmet"

const Ustawienia = ({ setMobileMenuBarValues }) => {
  useEffect(() => {
    if (setMobileMenuBarValues) {
      setMobileMenuBarValues({
        type: "menu",
        title: "Ustawienia",
      })
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Ustawienia użytkownika | Wspólnota 120</title>
      </Helmet>
      <PageTransition>
        <ContentWrapper paddingTop>
          <Heading style={{ marginBottom: "24px" }} strong>
            Ustawienia użytkownika
          </Heading>
          <DataForm />
          <PasswordForm />
        </ContentWrapper>
      </PageTransition>
    </>
  )
}

export default withMobileMenuBarContext(Ustawienia)
