import { gql } from "@apollo/client"

export const INFORMATIONS_QUERY = gql`
  query GetInformaions($meetingsWhere: JSON) {
    meetings(sort: "date:asc", limit: 5, where: $meetingsWhere) {
      date
      cookies_early_users {
        name
        surname
      }
      cookies_late_users {
        name
        surname
      }
    }
    information {
      leaders {
        name
        role
        email
        phone
      }
    }
    groups {
      name
      users {
        name
        surname
      }
    }
  }
`

export type INFORMATIONS_QUERY_TYPE = {
  meetings: {
    date: string
    cookies_early_users?: {
      name: string
      surname: string
    }[]
    cookies_late_users?: {
      name: string
      surname: string
    }[]
  }[]
  information: {
    leaders: {
      name?: string
      role?: string
      email?: string
      phone?: string
    }[]
  }
  groups: {
    name: string
    users: {
      name: string
      surname: string
    }[]
  }[]
}
