import { gql } from "@apollo/client"

export const TOPIC_GROUPS_QUERY = gql`
  query {
    topicGroups {
      title
      slug
      image {
        url
      }
    }
  }
`

export type TOPIC_GROUPS_QUERY_TYPE_ITEM = {
  title: string
  slug: string
  image?: {
    url?: string
  }
}

export type TOPIC_GROUPS_QUERY_TYPE = {
  topicGroups: TOPIC_GROUPS_QUERY_TYPE_ITEM[]
}
