import React from "react"
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons"
import { ButtonProps } from "components/Button/Button"
import Button from "components/Button"
import Icon from "components/Icon"

export type CollapseButtonProps = {
  isCollapsed: boolean
  onClick?: (e: React.MouseEvent) => void
} & ButtonProps

const CollapseButton = (props: CollapseButtonProps) => {
  return (
    <>
      {props.isCollapsed ? (
        <Button {...props}>
          Pokaż więcej <Icon icon={chevronDownOutline} />
        </Button>
      ) : (
        <Button {...props}>
          Ukryj <Icon icon={chevronUpOutline} />
        </Button>
      )}
    </>
  )
}

export default CollapseButton
