import { filterOptionsNewestOldest } from "components/Filter/Filter"
import React, { useEffect, useRef, useState } from "react"
import { ApolloQueryResult } from "@apollo/client"
import useEventListener from "@use-it/event-listener"
import { parseDateRangeForGql } from "tools/parseDateRangeForGql"
import { triggerDateSelect } from "@fullcalendar/common"

type Props<T, S> = {
  moreResults: T[]
  results?: T[]
  setMoreResults: (results: T[]) => void
  fetchMore: any
  page: number
  setPage: (page: number) => void
  loading: boolean
  setLoading: (state: boolean) => void
  completed: boolean
  setCompleted: (state: boolean) => void
  collectionName: string
  filter?: (results: T[]) => T[]
}

const InfiniteScroll = <T, S>({
  results,
  moreResults,
  filter,
  setMoreResults,
  fetchMore,
  page,
  setPage,
  loading,
  setLoading,
  completed,
  setCompleted,
  collectionName,
}: Props<T, S>) => {
  const infiniteScrollRef = useRef<HTMLDivElement>(null)
  const [filterCounter, setFilterCounter] = useState(0)

  useEffect(() => {
    if (!loading && !completed && results) {
      if (results.length < 10) {
        setLoading(true)
        setPage(page + 1)
      }
    }
  }, [results])

  useEffect(() => {
    if (page > 0) {
      fetchMore({
        variables: {
          offset: page * 10,
          limit: 10,
        },
      }).then(res => {
        if (res.data[collectionName].length < 10) {
          setCompleted(true)
        }

        const filteredResults: T[] = filter
          ? filter(res.data[collectionName])
          : res.data[collectionName]

        setFilterCounter(filterCounter + filteredResults.length)
        setMoreResults([...moreResults, ...filteredResults])

        if (filterCounter < 10 && !completed) {
          setPage(page + 1)
        } else {
          setFilterCounter(0)
          setLoading(false)
        }
      })
    }
  }, [page])

  const handleScroll = () => {
    if (infiniteScrollRef && infiniteScrollRef.current) {
      const rect = infiniteScrollRef.current.getBoundingClientRect()
      if (
        !loading &&
        !completed &&
        moreResults.length / 10 <= page &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        setPage(page + 1)
        setLoading(true)
      }
    }
  }

  useEventListener("scroll", handleScroll, document)

  return <div ref={infiniteScrollRef}></div>
}

export default InfiniteScroll
