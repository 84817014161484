import React, { useEffect, useMemo, useState } from "react"
import {
  MobileMenuBarContextType,
  withMobileMenuBarContext,
} from "components/UserMenuBar/MobileMenuBar/MobileMenuBarContext"
import { ViewContextConsumer } from "context/viewContext"
import PageTransition from "components/PageTransition"
import ContentWrapper from "components/ContentWrapper"
import Heading from "components/Heading"
import Grid from "components/Grid"
import Widget, { WidgetSection } from "components/Widget"
import Label from "components/Label"
import Button from "components/Button"
import { Link } from "gatsby"
import FiltersWidget from "components/FiltersWidget"
import Row from "components/Row"
import { useLocation } from "@reach/router"
import { getSlugFromLocation } from "tools/getSlugFromLocation"
import { FilterOption, FilterOptions } from "components/Filter/Filter"
import { useQuery } from "@apollo/client"
import InfiniteScroll from "components/InfiniteScroll"
import Spinner from "components/Spinner"
import { NEWS_QUERY, NEWS_TYPE, NEWS_TYPE_ITEM } from "./Aktualnosci.gql"
import NoResults from "components/NoResults"
import { parseStringDate } from "tools/parseStringDate"
import { getTextFromHtml } from "tools/getTextFromHtml"
import { parseDateRangeForGql } from "tools/parseDateRangeForGql"
import { Helmet } from "react-helmet"

export const getSpotkaniaElementQueryVariables = (
  startDate: Date | null,
  endDate: Date | null,
  selectedFilter: FilterOption,
  slug: string
) => {
  const [dateGt, dateLt] = parseDateRangeForGql(startDate, endDate)
  return {
    sort: selectedFilter.value,
    limit: 10,
    offset: 0,
    where: {
      published_at_lt: dateLt,
      published_at_gt: dateGt,
    },
  }
}

const Aktualnosci = ({ setMobileMenuBarValues }: MobileMenuBarContextType) => {
  const [page, setPage] = useState(0)
  const [completed, setCompleted] = useState(false)
  const [loading, setLoading] = useState(true)
  const [moreResults, setMoreResults] = useState<NEWS_TYPE_ITEM[]>([])

  const location = useLocation()
  const slug = getSlugFromLocation(location)
  const filterOptions: FilterOptions = {
    newest: {
      title: "Najnowsze",
      value: "published_at:desc",
    },
    oldest: {
      title: "Najstarsze",
      value: "published_at:asc",
    },
  }

  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [selectedFilter, setSelectedFilter] = useState(filterOptions.newest)

  const [queryVariables, setQueryVariables] = useState(
    getSpotkaniaElementQueryVariables(startDate, endDate, selectedFilter, slug)
  )

  const { error, data, refetch, fetchMore } = useQuery<NEWS_TYPE>(NEWS_QUERY, {
    variables: queryVariables,
  })

  const results = useMemo(
    () => (data && data.news ? [...data.news, ...moreResults] : []),
    [data, moreResults]
  )

  useEffect(() => {
    setLoading(true)
    refetch(queryVariables).then(() => {
      setLoading(false)
      setPage(0)
      setCompleted(false)
      setMoreResults([])
    })
  }, [queryVariables])

  useEffect(() => {
    setMobileMenuBarValues!({
      type: "menu",
      title: `Aktualności`,
    })
  }, [])

  useEffect(() => {
    setQueryVariables(
      getSpotkaniaElementQueryVariables(
        startDate,
        endDate,
        selectedFilter,
        slug
      )
    )
  }, [startDate, endDate, selectedFilter])

  return (
    <>
      <Helmet>
        <title>Aktualności | Wspólnota 120</title>
      </Helmet>
      <ViewContextConsumer>
        {viewContext => (
          <PageTransition absolute>
            {(viewContext?.media.xs || viewContext?.media.sm) && (
              <FiltersWidget
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                selectedFilter={selectedFilter}
                filterOptions={filterOptions}
                setSelectedFilter={setSelectedFilter}
                fixed
                past
              />
            )}
            <ContentWrapper paddingTop full>
              {(viewContext?.media.md ||
                viewContext?.media.lg ||
                viewContext?.media.xl) && (
                <Row flexWrap>
                  <Heading strong>Aktualności</Heading>

                  <FiltersWidget
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    selectedFilter={selectedFilter}
                    filterOptions={filterOptions}
                    setSelectedFilter={setSelectedFilter}
                    past
                  />
                </Row>
              )}
              <div style={{ marginTop: "24px" }}>
                {results.length ? (
                  <Grid>
                    {results.map((article, index) => (
                      <Widget gray fill key={article.slug}>
                        <WidgetSection style={{ marginBottom: "8px" }}>
                          <Heading level={3} style={{ margin: "0 0 8px 0" }}>
                            {article.title}
                          </Heading>
                          <Label>{parseStringDate(article.published_at)}</Label>
                          <div style={{ marginTop: "8px" }}>
                            {getTextFromHtml(article.content).substring(0, 120)}
                            ...
                          </div>
                        </WidgetSection>
                        <Button
                          as={Link}
                          to={"/konto/aktualnosci/" + article.slug}
                          block
                        >
                          Czytaj dalej
                        </Button>
                      </Widget>
                    ))}
                  </Grid>
                ) : (
                  !loading && (
                    <NoResults>Brak wyników spełniających kryteria</NoResults>
                  )
                )}
                {fetchMore && (
                  <InfiniteScroll
                    moreResults={moreResults}
                    setMoreResults={setMoreResults}
                    fetchMore={fetchMore}
                    page={page}
                    setPage={setPage}
                    loading={loading}
                    setLoading={setLoading}
                    completed={completed}
                    setCompleted={setCompleted}
                    collectionName={"news"}
                  />
                )}
                <div style={{ margin: "24px 24px 24px 0" }}>
                  {loading && <Spinner size={32} />}
                </div>
              </div>
            </ContentWrapper>
          </PageTransition>
        )}
      </ViewContextConsumer>
    </>
  )
}

export default withMobileMenuBarContext(Aktualnosci)
