import styled from "styled-components"

const Header = styled.header`
  position: relative;

  padding: 0 0 16px 0;

  ${props => props.theme.media.md} {
    padding: 16px 0 20px 42px;
    border-left: 2px solid #000;

    &::before {
      position: absolute;
      content: "";
      display: block;
      top: -99px;
      height: 100px;
      left: -2px;
      width: 2px;
      background-color: #000;
    }

    margin-left: -42px;
  }
`

const Section = styled.div<{
  last?: boolean
  widgetLine?: boolean
  noLine?: boolean
}>`
  padding: 8px 0;
  position: relative;

  ${props =>
    !props.noLine &&
    `&::before {
    position: absolute;
    display: block;
    content: "";
    left: -42px;
    top: 0;
    width: 2px;
    background-color: #000;

    ${props.last ? `height: 32px; border-radius: 0 0 2px 2px;` : `bottom: 0;`}
  }`}

  ${props =>
    props.widgetLine &&
    `
    &::after {
      position: absolute;
      content: "";
      display: block;
      left: -42px;
      top: 30px;
      height: 2px;
      width: 30px;
      border-radius: 2px 2px 2px 2px;
      background-color: #000;
  `}
`

const Entry = {
  Header,
  Section,
}

export default Entry
