import React, { FC } from "react"
import { Location, Router } from "@reach/router"
import { CSSTransition, TransitionGroup } from "react-transition-group"

const FadeTransitionRouter: FC = props => (
  <Location>
    {({ location }) => (
      <TransitionGroup className="transition-group">
        <CSSTransition key={location.key} classNames="fade" timeout={1000}>
          {/* the only difference between a router animation and
              any other animation is that you have to pass the
              location to the router so the old screen renders
              the "old location" */}
          <Router location={location} className="router">
            {props.children}
          </Router>
        </CSSTransition>
      </TransitionGroup>
    )}
  </Location>
)

export default FadeTransitionRouter
