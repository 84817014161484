import ContentWrapper from "components/ContentWrapper"
import PageTransition from "components/PageTransition"
import React, { useEffect, useState } from "react"

import { ViewContextConsumer } from "context/viewContext"
import {
  MobileMenuBarContextType,
  withMobileMenuBarContext,
} from "components/UserMenuBar/MobileMenuBar/MobileMenuBarContext"
import Row from "components/Row"
import Heading from "components/Heading"
import Link from "components/Link"
import Widget, { WidgetSection } from "components/Widget"
import Filter from "components/Filter"
import Styled from "components/FiltersWidget/FiltersWidget.styled"
import { useQuery } from "@apollo/client"
import {
  SONGS_QUERY,
  SONGS_QUERY_TYPE,
  SongType,
} from "konto/Spiewnik/Spiewnik.gql"
import { FilterOption, FilterOptions } from "components/Filter/Filter"
import { Helmet } from "react-helmet"

type SortedSongs = {
  [key: string]: SongType[]
}

const sortSongs = (songs: SongType[]) => {
  const sortedSongs: SortedSongs = {}
  for (const song of songs) {
    const firstLetter = song.title[0].toUpperCase()
    if (sortedSongs[firstLetter]) {
      sortedSongs[firstLetter].push(song)
    } else {
      sortedSongs[firstLetter] = [song]
    }
  }
  return sortedSongs
}

const Spiewnik = ({ setMobileMenuBarValues }: MobileMenuBarContextType) => {
  const { loading, error, data } = useQuery<SONGS_QUERY_TYPE>(SONGS_QUERY)

  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    all: {
      title: "Wszystkie",
      value: "all",
    },
  })

  const [sortedSongs, setSortedSongs] = useState<SortedSongs>({})

  useEffect(() => {
    if (data) {
      const sortedSongs = sortSongs(data.piosenkis)

      const songsFilters: FilterOptions = {}

      Object.keys(sortedSongs).map(letter => {
        songsFilters[letter] = {
          title: letter,
          value: letter,
        }
      })

      setSortedSongs(sortedSongs)

      setFilterOptions({
        all: {
          title: "Wszystkie",
          value: "all",
        },
        ...songsFilters,
      })
    }
  }, [data, loading])

  const [selectedFilter, setSelectedFilter] = useState<FilterOption>(
    filterOptions.all
  )

  useEffect(() => {
    setMobileMenuBarValues!({
      type: "menu",
      title: "Prezentacje",
    })
  }, [])

  useEffect(() => {
    setMobileMenuBarValues!({
      type: "menu",
      title: "Śpiewnik",
    })
  }, [])

  return (
    <>
      <Helmet>
        <title>Śpiewnik | Wspólnota 120</title>
      </Helmet>
      <ViewContextConsumer>
        {viewContext => (
          <PageTransition>
            {(viewContext?.media.xs || viewContext?.media.sm) && (
              <Styled.Widget fixed>
                <Widget noRadius>
                  <WidgetSection style={{ padding: "4px" }}>
                    <Row justifyContent={"flex-end"}>
                      <Filter
                        currentOption={selectedFilter}
                        options={filterOptions}
                        handleSelectFn={setSelectedFilter}
                        width={128}
                      />
                    </Row>
                  </WidgetSection>
                </Widget>
              </Styled.Widget>
            )}
            <ContentWrapper paddingTop>
              {(viewContext?.media.md ||
                viewContext?.media.lg ||
                viewContext?.media.xl) && (
                <Row flexWrap>
                  <Heading strong>Śpiewnik</Heading>

                  <Styled.Widget>
                    <Widget>
                      <WidgetSection style={{ padding: "4px" }}>
                        <Row justifyContent={"flex-end"}>
                          <Filter
                            currentOption={selectedFilter}
                            options={filterOptions}
                            handleSelectFn={setSelectedFilter}
                            width={128}
                          />
                        </Row>
                      </WidgetSection>
                    </Widget>
                  </Styled.Widget>
                </Row>
              )}
              <div>
                {Object.keys(sortedSongs).map(keyName => {
                  if (
                    selectedFilter.value === "all" ||
                    selectedFilter.value === keyName
                  ) {
                    return (
                      <div key={keyName} style={{ marginTop: "12px" }}>
                        <Heading level={3}>{keyName}</Heading>
                        <div style={{ marginBottom: "16px" }}>
                          {sortedSongs[keyName].map(item => (
                            <div
                              key={item.title}
                              style={{ marginBottom: "8px" }}
                            >
                              <Link to={"/konto/spiewnik/" + item.slug}>
                                {item.title}
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
            </ContentWrapper>
          </PageTransition>
        )}
      </ViewContextConsumer>
    </>
  )
}

export default withMobileMenuBarContext(Spiewnik)
