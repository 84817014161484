import PageTransition from "components/PageTransition"
import ContentWrapper from "components/ContentWrapper"
import Link from "components/Link"
import { chevronBack } from "ionicons/icons"
import { useLocation } from "@reach/router"
import Heading from "components/Heading"
import Subheading from "components/Subheading"
import React, { useEffect } from "react"
import Entry from "components/Entry"
import Gallery from "components/Gallery/Gallery"
import {
  MobileMenuBarContextType,
  withMobileMenuBarContext,
} from "components/UserMenuBar/MobileMenuBar/MobileMenuBarContext"
import { ViewContextConsumer } from "context/viewContext"
import { getSlugFromLocation } from "tools/getSlugFromLocation"
import { useQuery } from "@apollo/client"
import {
  AKTUALNOSCI_WPIS_QUERY,
  AKTUALNOSCI_WPIS_QUERY_TYPE,
} from "./AktualnosciWpis.gql"
import { parseStringDate } from "tools/parseStringDate"
import config from "config/config"
import Spinner from "components/Spinner"
import { Helmet } from "react-helmet"

const AktualnosciWpis = ({
  setMobileMenuBarValues,
}: MobileMenuBarContextType) => {
  const location = useLocation()
  const slug = getSlugFromLocation(location)

  const { loading, error, data } = useQuery<AKTUALNOSCI_WPIS_QUERY_TYPE>(
    AKTUALNOSCI_WPIS_QUERY,
    {
      variables: {
        where: {
          slug: slug,
        },
      },
    }
  )

  useEffect(() => {
    if (data && data.news[0]) {
      setMobileMenuBarValues?.({
        type: "entry",
        title: data.news[0].title,
        subtitle: `Aktualności, ${parseStringDate(data.news[0].published_at)}`,
        backUrl: `/konto/aktualnosci`,
      })
    }
  }, [data])

  if (data && data.news && data.news[0]) {
    const { title, content, gallery, published_at } = data.news[0]

    const photos = gallery.map(item => ({
      src: config.uploadPrefix + item.url,
      width: item.width,
      height: item.height,
    }))

    return (
      <>
        <Helmet>
          <title>{title} | Wspólnota 120</title>
        </Helmet>
        <ViewContextConsumer>
          {viewContext => (
            <PageTransition>
              <ContentWrapper>
                <Entry.Header>
                  {(viewContext?.media.md ||
                    viewContext?.media.lg ||
                    viewContext?.media.xl) && (
                    <Link icon={chevronBack} to={`/konto/aktualnosci`}>
                      Powrót
                    </Link>
                  )}
                  <Heading style={{ marginTop: "10px" }}>{title}</Heading>
                  <Subheading>
                    Aktualności, {parseStringDate(data.news[0].published_at)}
                  </Subheading>
                </Entry.Header>
                <Entry.Section>
                  <div
                    style={{ marginBottom: "12px" }}
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </Entry.Section>
                <Entry.Section>
                  <div style={{ marginBottom: "12px" }}>
                    <Gallery images={photos} />
                  </div>
                </Entry.Section>
              </ContentWrapper>
            </PageTransition>
          )}
        </ViewContextConsumer>
      </>
    )
  } else if (loading) {
    return (
      <PageTransition>
        <ContentWrapper>
          <Spinner size={32} />
        </ContentWrapper>
      </PageTransition>
    )
  } else if (error) {
    return (
      <>
        <div></div>
      </>
    )
  }
  return null
}

export default withMobileMenuBarContext(AktualnosciWpis)
