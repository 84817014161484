import React from "react"
import Link from "components/Link"
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons"
import { LinkProps } from "components/Link/Link"

export type CollapseLinkProps = {
  isCollapsed: boolean
  customButtonShowText?: string
} & LinkProps

const CollapseLink = (props: CollapseLinkProps) => {
  return (
    <>
      {props.isCollapsed ? (
        <Link icon={chevronDownOutline} iconPosition={"right"} {...props}>
          {props.customButtonShowText || "Pokaż więcej"}
        </Link>
      ) : (
        <Link icon={chevronUpOutline} iconPosition={"right"} {...props}>
          Ukryj
        </Link>
      )}
    </>
  )
}

export default CollapseLink
