import { gql } from "@apollo/client"

export const PAST_TRIPS_QUERY = gql`
  query GetIncomingTrips(
    $sort: String!
    $limit: Int
    $offset: Int
    $where: JSON
  ) {
    trips(sort: $sort, limit: $limit, start: $offset, where: $where) {
      title
      location
      from
      to
      slug
      image {
        url
      }
    }
  }
`

export type PAST_TRIPS_QUERY_TYPE_ITEM = {
  title: string
  location?: string
  from?: string
  to?: string
  slug: string
  image?: {
    url: string
  }
}

export type PAST_TRIPS_QUERY_TYPE = {
  trips: PAST_TRIPS_QUERY_TYPE_ITEM[]
}
