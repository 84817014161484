import styled from "styled-components"

const Label = styled.label<{ black?: boolean }>`
  display: block;
  font-weight: bold;
  line-height: 1.4;
  font-size: 13px;
  letter-spacing: 0.5px;
  color: ${props =>
    props.black ? props.theme.color.black : props.theme.color.primaryText};
  text-transform: uppercase;
`

export default Label
