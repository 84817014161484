import { gql } from "@apollo/client"

export const AKTUALNOSCI_WPIS_QUERY = gql`
  query getAktualnosciWpis($where: JSON) {
    news(where: $where) {
      title
      slug
      content
      published_at
      gallery {
        url
        width
        height
      }
    }
  }
`

export type AKTUALNOSCI_WPIS_QUERY_TYPE = {
  news: {
    title: string
    slug: string
    content: string
    published_at: string
    gallery: {
      url: string
      width: number
      height: number
    }[]
  }[]
}
