import styled from "styled-components"

const UserDataWrapper = styled.div`
  ${props => props.theme.media.lg} {
    display: grid;
    grid-template-columns: 1fr 240px;
    gap: 48px;
  }
`

const UserImageDelete = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px;
  border-radius: 50%;
  border: 2px solid #000;
  width: 42px;
  height: 42px;
  background-color: #fff;
  display: none;
`

const UserImageAdd = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  background-color: rgba(40, 40, 40, 0.6);
  display: none;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0 4px #000;
  font-size: 18px;
  cursor: pointer;
  letter-spacing: 1px;
`

const UserImageWrapper = styled.div`
  border-radius: 50%;
  border: 2px solid #000;
  width: 220px;
  height: 220px;
  position: relative;
  margin: auto;

  &:hover {
    ${UserImageDelete} {
      display: block;
    }

    ${UserImageAdd} {
      display: flex;
    }
  }
`

const UserImage = styled.div<{ backgroundImage: string }>`
  background-size: cover;
  background-position: center;
  background-image: url("${props => props.backgroundImage}");
  margin: auto;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  bottom: 12px;
  left: 12px;
  right: 12px;
`

const UserImageInput = styled.input`
  position: fixed;
  top: -1000px;
  left: -1000px;
  opacity: 0;
`

const Styled = {
  UserDataWrapper,
  UserImage,
  UserImageWrapper,
  UserImageDelete,
  UserImageAdd,
  UserImageInput,
}

export default Styled
