import { gql } from "@apollo/client"

export const NEWS_QUERY = gql`
  query getNews($sort: String!, $limit: Int, $offset: Int, $where: JSON) {
    news(sort: $sort, limit: $limit, start: $offset, where: $where) {
      title
      slug
      content
      published_at
    }
  }
`

export type NEWS_TYPE_ITEM = {
  title: string
  slug: string
  content: string
  published_at: string
}

export type NEWS_TYPE = {
  news: NEWS_TYPE_ITEM[]
}
